import { QuestPointer } from "@worldwidewebb/client-quests";

export const QUEST_POINTER_REFETCH_INTERVAL = 10_000;

export interface QuestPointerStatus {
  questPointerId?: string;
  nodeId?: string;
  isActive?: boolean;
  hasError?: boolean;
  isAwaiting?: boolean;
  isFinished?: boolean;
}

export function mapQuestPointerToQuestPointerStatus(questPointer?: QuestPointer): QuestPointerStatus {
  return {
    questPointerId: questPointer?.pointerId,
    nodeId: questPointer?.nodeId,
    isActive: questPointer != null,
    hasError: questPointer?.errored,
    isAwaiting: (questPointer?.waitReasons?.length ?? 0) > 0,
    isFinished: questPointer?.completed,
  };
}
