import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import useCreateQuestPointer from "../hooks/quests/useCreateQuestPointer";
import useDeleteQuestPointer from "../hooks/quests/useDeleteQuestPointer";
import useUpdateQuestPointer from "../hooks/quests/useUpdateQuestPointer";
import { useQuestSearchParamsProvider } from "./quest/QuestSearchParamsProvider";

interface ContextType {
  selectedQuestPointerId: string | null;
  changeSelectedQuestPointer: (questPointerId: string) => void;
  isCreating: boolean;
  createTargetedQuestPointer: (nodeId: string) => void;
  isUpdating: boolean;
  updateSelectedQuestPointer: (nodeId: string) => void;
  isDeleting: boolean;
  deleteSelectedQuestPointer: (questPointerId: string) => void;
}

const Context = createContext<ContextType | null>(null);

export function QuestPointerProvider({ children }: PropsWithChildren) {
  const { id: questId = "" } = useParams();
  const { userId } = useQuestSearchParamsProvider();

  const [selectedQuestPointerId, setSelectedQuestPointerId] = useState<string | null>(null);

  const changeSelectedQuestPointer = useCallback(
    (questPointerId: string) =>
      setSelectedQuestPointerId((selectedQuestPointerId) =>
        selectedQuestPointerId !== questPointerId ? questPointerId : null
      ),
    []
  );

  const { isCreating, createQuestPointer } = useCreateQuestPointer();

  const createTargetedQuestPointer = useCallback(
    (nodeId: string) => {
      createQuestPointer({ userId, questId, nodeId });

      setSelectedQuestPointerId(null);
    },
    [createQuestPointer, questId, userId]
  );

  const { isUpdating, updateQuestPointer } = useUpdateQuestPointer();

  const updateSelectedQuestPointer = useCallback(
    (nodeId: string) => {
      if (selectedQuestPointerId == null) {
        return;
      }

      updateQuestPointer({ questPointerId: selectedQuestPointerId, nodeId, userId });

      setSelectedQuestPointerId(null);
    },
    [updateQuestPointer, selectedQuestPointerId, userId]
  );

  const { isDeleting, deleteQuestPointer } = useDeleteQuestPointer();

  const deleteSelectedQuestPointer = useCallback(
    (questPointerId: string) => {
      if (selectedQuestPointerId == questPointerId) {
        setSelectedQuestPointerId(null);
      }

      deleteQuestPointer({ questPointerId, userId });
    },
    [deleteQuestPointer, selectedQuestPointerId]
  );

  return (
    <Context.Provider
      value={{
        selectedQuestPointerId,
        changeSelectedQuestPointer,
        isCreating,
        createTargetedQuestPointer,
        isUpdating,
        updateSelectedQuestPointer,
        isDeleting,
        deleteSelectedQuestPointer,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useQuestPointerProvider() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useQuestPointerProvider used outside of QuestPointerProvider");
  }

  return context;
}
