import { Text } from "@chakra-ui/react";
import { memo, useCallback, useMemo } from "react";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { useReactFlowStoreProvider } from "../../context/ReactFlowStoreContext";
import useUpdateQuestMetaDatas from "../../hooks/quests/useUpdateQuestMetaDatas";

function QuestsArchiveButton() {
  const { nodes } = useReactFlowStoreProvider();

  const quests = useMemo(() => nodes.filter(({ selected }) => selected), [nodes]);

  const { isUpdating, updateQuestMetaDatas } = useUpdateQuestMetaDatas();

  const archiveQuests = useCallback(() => {
    updateQuestMetaDatas(
      quests.map(({ id: questId }) => ({ questId, isArchived: true, isReady: false, status: "draft" }))
    );
  }, [updateQuestMetaDatas, quests]);

  if (quests.length === 0) {
    return null;
  }

  return (
    <ConfirmModal
      title={`ARCHIVE QUESTS (${quests.length})`}
      tooltip={"archives quests after confirmation"}
      color={"white"}
      onConfirm={archiveQuests}
      isLoading={isUpdating}
      isDisabled={isUpdating}
    >
      <Text color={"white"} fontWeight={500}>
        Are you sure you want to archive {quests.length} quest(s)
      </Text>
    </ConfirmModal>
  );
}

export default memo(QuestsArchiveButton);
