import { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useEnergies from "../../hooks/energies/useEnergies";

interface SelectEnergyProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

function SelectEnergy({ color, value, setValue, ...chakraProps }: SelectEnergyProps) {
  const { isLoading, error, energies = [] } = useEnergies();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return energies
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ energyId, displayName }) => [energyId, displayName]);
    },
    [energies]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Energy"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
}

export default SelectEnergy;
