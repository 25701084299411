import { QuestWithId } from "./quest";

export type DeployVersionType = "major" | "minor" | "patch";

export interface Deploy extends Pick<QuestWithId, "deployVersion" | "deployMessage"> {
  deployVersionType: DeployVersionType;
}

export const deployVersionTypes: DeployVersionType[] = ["major", "minor"];

export const deployVersions: Partial<Record<DeployVersionType, string>> = {
  minor: "minor (No QA)",
  major: "major (Triggers QA)",
}
