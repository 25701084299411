import { Center, Checkbox, FormControl, Heading, MenuItem, Stack, Text } from "@chakra-ui/react";
import { ChakraProps } from "@chakra-ui/system";
import SearchBar from "../../base/SearchBar";
import { NodeCategory, NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo, useCallback, useMemo, useState } from "react";
import QuestNodeCategorySelector from "./QuestNodeCategorySelector";
import QuestNodeTypeList from "./QuestNodeTypeList";
import useCreateQuestNode from "./useCreateQuestNode";
import { Node, useReactFlow } from "reactflow";
import { EdgeType } from "../../../models/edgeType";
import { useCopyAndPaste } from "../../../hooks/useCopyAndPaste";
import { ulid } from "ulid";

interface InsertStickyNodeButtonProps {
  x: number;
  y: number;
}

// TODO: VERIFICATION PENDING
function InsertStickyNodeButton({ x, y }: InsertStickyNodeButtonProps) {
  const { setNodes } = useReactFlow<NodeType, EdgeType>();

  const { paste } = useCopyAndPaste();

  const { screenToFlowPosition } = useReactFlow<NodeType, EdgeType>();

  const insertStickyNode = useCallback(() => {
    const node: Node<NodeType> = {
      id: ulid(),
      type: "StickyNode",
      data: {
        nodeName: "sticky",
        nodeClass: "meta",
        nodeData: {
          displayName: "",
          description: "",
        },
      },
      position: screenToFlowPosition({ x, y }),
    };

    const nodes = [node];

    paste(nodes).then(({ nodes }) => {
      const [createdNode] = nodes;

      setNodes((nodes: Node<NodeType>[]) => [...nodes, createdNode]);
    });
  }, [setNodes, paste, screenToFlowPosition, x, y]);

  return (
    <MenuItem display={"flex"} textAlign={"center"} p={0} onClick={insertStickyNode}>
      <Heading flexGrow={1} size={"xs"}>
        <Text color={"yellow.600"} casing={"uppercase"} p={2}>
          Insert Sticky Note
        </Text>
      </Heading>
    </MenuItem>
  );
}

interface QuestNodeSelectorProps extends ChakraProps {
  questNodeTypes: NodeType[];
  x: number;
  y: number;
}

function QuestNodeSelector({ questNodeTypes, x, y, maxH }: QuestNodeSelectorProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [showExperimentalNodes, setShowExperimentalNodes] = useState<boolean>(false);
  const [selectedNodeCategories, setSelectedNodeCategories] = useState<NodeCategory[]>([]);

  const filteredQuestNodeTypes = useMemo(
    () =>
      questNodeTypes
        .filter(({ nodeCategory }) => {
          if (nodeCategory == null) {
            return true;
          }

          if (selectedNodeCategories.length === 0) {
            return true;
          }

          return selectedNodeCategories.includes(nodeCategory);
        })
        .filter(({ isReady }) => {
          return showExperimentalNodes ? true : isReady;
        })
        .filter(({ label }) => {
          return label?.toLowerCase().includes(searchValue.toLowerCase().trim());
        })
        .sort(({ label: a = "" }, { label: b = "" }) => {
          return a.localeCompare(b);
        }),
    [questNodeTypes, selectedNodeCategories, showExperimentalNodes, searchValue]
  );

  return (
    <Stack bg={"gray.900"} w={"xs"}>
      <Stack>
        <InsertStickyNodeButton x={x} y={y} />

        <QuestNodeCategorySelector
          selectedNodeCategories={selectedNodeCategories}
          setSelectedNodeCategories={setSelectedNodeCategories}
        />

        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={"Search for node types..."}
          variant={"block"}
        />

        <FormControl textAlign={"center"}>
          <Checkbox
            isChecked={showExperimentalNodes}
            onChange={({ target: { checked } }) => setShowExperimentalNodes(checked)}
          >
            <Text casing={"uppercase"} color={"white"} fontWeight={500}>
              Show Experimental Nodes
            </Text>
          </Checkbox>
        </FormControl>
      </Stack>

      {filteredQuestNodeTypes.length !== 0 ? (
        <Stack spacing={0} maxH={maxH}>
          <QuestNodeTypeList questNodeTypes={filteredQuestNodeTypes} x={x} y={y} searchValue={searchValue} />
        </Stack>
      ) : (
        <Center p={2}>
          <Text color={"white"} casing={"uppercase"} fontWeight={500}>
            No results found
          </Text>
        </Center>
      )}
    </Stack>
  );
}

export default memo(QuestNodeSelector);
