import { useCallback } from "react";
import { QuestWithId } from "../../models/api/quest";
import useUpdateQuestMetaDataAndNodeData from "./useUpdateQuestMetaDataAndNodeData";

export default function useUnarchiveQuest(questId: string, quest?: QuestWithId) {
  const { isUpdating: isUnarchiving, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const unarchiveQuest = useCallback(() => {
    if (quest == null) {
      return;
    }

    const { data } = quest;
    const nodes = data?.nodes ?? [];
    const edges = data?.edges ?? [];

    updateQuestMetaDataAndNodeData({
      questId,
      partialQuest: { isArchived: false },
      questData: { nodes, edges },
    });
  }, [questId, quest]);

  return {
    isUnarchiving,
    unarchiveQuest,
  };
}
