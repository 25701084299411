import { memo } from "react";
import { HStack, Link, Text } from "@chakra-ui/react";
import useQuest from "../../hooks/quests/useQuest";
import Tag from "../../ui/base/chakra/Tag";
import { QaStatus } from "../../models/api/quest";

interface QuestQaStatusProps {
  questId: string;
}

function QuestQaStatus({ questId }: QuestQaStatusProps) {
  const { isLoading, error, quest } = useQuest(questId);

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (quest == null) {
    return null;
  }

  const { qaStatus } = quest;

  if (qaStatus == null) {
    return null;
  }

  const status = qaStatus.status?.toLowerCase() as QaStatus;
  const issueId = qaStatus.issueId;

  let href = undefined;
  let color = undefined;

  if (status === "pass" || status === "in qa") {
    href = `https://linear.app/worldwide-webb/issue/${issueId}`;
    color = status === "pass" ? "green.600" : "yellow.600";
  } else {
    href = `https://retool.dev.webb.party/apps/4cdbcb84-a4fb-11ef-8b4a-433f1ad145ca/Quest%20QA%20Status`;
    color = "red.800";
  }

  return (
    <HStack gap={2}>
      <Text color={"white"} casing={"uppercase"}>
        QA Status
      </Text>

      <Link href={href} target={"_blank"}>
        <Tag>
          <Text color={color} casing={"uppercase"}>
            {status}
          </Text>
        </Tag>
      </Link>
    </HStack>
  );
}

export default memo(QuestQaStatus);
