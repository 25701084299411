import { JSONSchema7Definition } from "json-schema";

export const JSONSchemaDialog: JSONSchema7Definition = {
  type: "object",
  properties: {
    speakerPassages: {
      type: "array",
      items: {
        type: "object",
        properties: {
          passageId: {
            type: "string",
          },
          passageType: {
            type: "string",
            enum: ["continue", "dialog", "dialog_ai"],
          },
          passage: {
            type: "string",
          },
          passagePrompt: {
            type: "string",
          },
          speakerType: {
            type: "string",
            enum: ["none", "npc", "npc_input", "player"],
          },
          speakerId: {
            type: "string",
          },
        },
      },
    },
    choices: {
      type: "array",
      items: {
        type: "object",
        properties: {
          choiceId: {
            type: "string",
          },
          choice: {
            type: "string",
          },
          isConditional: {
            type: "boolean",
          },
        },
      },
    },
    clearScrollbackHistory: {
      type: "boolean",
    },
  },
};
