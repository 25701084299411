import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import useSearchParamsState from "../../hooks/searchParams/useSearchParamsState";

interface ContextType {
  userId: string;
  setUserId: Dispatch<SetStateAction<string>>;
  showQuestPointers: boolean;
  setShowQuestPointers: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<ContextType | null>(null);

interface QuestSearchParamsProviderProps extends PropsWithChildren {
  initialUserId: string;
}

export function QuestSearchParamsProvider({ initialUserId, children }: QuestSearchParamsProviderProps) {
  const [searchParams] = useSearchParams();
  const initialShowQuestPointers = searchParams.get("showQuestPointers") === "true";

  const [userId, setUserId] = useSearchParamsState("userId", initialUserId);
  const [showQuestPointers, setShowQuestPointers] = useSearchParamsState("showQuestPointers", initialShowQuestPointers);

  return (
    <Context.Provider value={{ userId, setUserId, showQuestPointers, setShowQuestPointers }}>
      {children}
    </Context.Provider>
  );
}

export function useQuestSearchParamsProvider() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useQuestSearchParamsProvider used outside of QuestSearchParamsProvider");
  }

  return context;
}
