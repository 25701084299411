import { QuestEditorApi, QuestPointer } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getQuestPointersForUser(questId?: string, userId?: string): Promise<QuestPointer[]> {
  if (questId == null) {
    return [];
  }

  if (userId == null) {
    return [];
  }

  try {
    const { data: questPointers } = await questEditorApi.getEditorQuestPointersForUser(userId, questId);

    return questPointers;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createQuestPointerForUser(userId: string, questId: string, nodeId: string): Promise<void> {
  try {
    await questEditorApi.createEditorQuestPointerForUser(userId, questId, { nodeId });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateQuestPointerForUser(userId: string, questPointerId: string, nodeId: string): Promise<void> {
  try {
    await questEditorApi.moveEditorPointerForUser(userId, questPointerId, nodeId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteQuestPointerForUser(userId: string, questPointerId: string): Promise<void> {
  try {
    await questEditorApi.deleteEditorPointerForUser(userId, questPointerId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
