import React, { memo, useCallback, useEffect } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType, TargetHandle } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  ChakraProps,
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import QuestPointerContainer from "../../quests/QuestPointerContainer";

interface NodeData {
  flowTargetCount: number;
  clearQuestState: boolean;
}

interface EndNodeConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeId: string;
  nodeData: NodeData;
  targetHandles: TargetHandle[];
  color?: string;
}

const EndNodeConfigurationModal: React.FC<EndNodeConfigurationModalProps> = ({
  isOpen,
  onClose,
  nodeId,
  nodeData,
  targetHandles,
  color,
}) => {
  const flowTargetCount = nodeData?.flowTargetCount ?? 1;
  const clearQuestState = nodeData?.clearQuestState ?? true;

  const { register, reset, handleSubmit } = useForm<NodeData>({
    defaultValues: {
      flowTargetCount,
      clearQuestState,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();
  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ flowTargetCount, clearQuestState }: NodeData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.flowTargetCount = flowTargetCount;
        nodeData.clearQuestState = clearQuestState;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });

      onClose();
    },
    [onClose, reactFlow, nodeId]
  );

  useEffect(() => {
    reset({
      flowTargetCount,
      clearQuestState,
    });
  }, [flowTargetCount, clearQuestState]);

  const handleCancel = useCallback(() => {
    reset({
      flowTargetCount,
      clearQuestState,
    });

    onClose();
  }, [onClose, reset, flowTargetCount, clearQuestState]);

  useEffect(() => {
    const currentFlowTargetCount = targetHandles.filter(({ handleName }) => handleName === "end").length;

    if (currentFlowTargetCount === flowTargetCount) {
      return;
    }

    const targetHandlesExcludingIn = targetHandles.filter(({ handleName }) => handleName !== "end");

    const targetHandlesIncludingIn = targetHandles.filter(({ handleName }) => handleName === "end");

    if (currentFlowTargetCount < flowTargetCount) {
      const handlesToInsert = flowTargetCount - currentFlowTargetCount;

      targetHandlesIncludingIn.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "END",
            handleName: "end",
            handleType: "target",
            handleCategory: "end",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentFlowTargetCount - flowTargetCount;

      targetHandlesIncludingIn.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingIn, ...targetHandlesExcludingIn]);
  }, [flowTargetCount, updateNodeTargetHandles, targetHandles]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  END Port Count
                </Text>
              </FormLabel>
              <NumberInput defaultValue={1} step={1} min={1} max={16}>
                <NumberInputField
                  id={"flowTargetCount"}
                  {...register("flowTargetCount", { valueAsNumber: true, min: 1, max: 5 })}
                  color={color}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Clear Quest State
                </Text>
              </FormLabel>
              <Checkbox id={"clearQuestState"} {...register("clearQuestState")} color={color} />
            </FormControl>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const EndNode: React.FC<NodeProps<NodeType>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id: nodeId,
    data: { color, nodeData = {}, targetHandles = [] },
  } = props;

  return (
    <>
      <QuestPointerContainer mx={1} mb={1} color={color} nodeId={nodeId} />

      <BaseNodeWithChildren {...props}>
        <Box p={2}>
          <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
            Configure Node
          </Button>

          <EndNodeConfigurationModal
            isOpen={isOpen}
            onClose={onClose}
            nodeId={nodeId}
            nodeData={nodeData as NodeData}
            targetHandles={targetHandles}
            color={color}
          />
        </Box>
      </BaseNodeWithChildren>
    </>
  );
};

export default memo(EndNode);
