import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { QuestWithId } from "../models/api/quest";
import { getQuests } from "../api/quests/quests";
import { updateQuestNodes } from "../store/quests";
import { useDispatch } from "react-redux";
import { useStoreApi } from "reactflow";
import { putCachedQuests } from "../db";
import { useSearchParams } from "react-router-dom";

interface Quests {
  updateQuestsQuery: (questsQuery: string) => void;
  quests: QuestWithId[];
  isLoading: boolean;
  error: Error | null;
}

const QuestsContext = createContext<Quests | null>(null);

export function QuestsProvider({ children }: PropsWithChildren) {
  const [questsQuery, setQuestsQuery] = useState<string>("");
  const [searchParams] = useSearchParams();
  const isArchived = Boolean(searchParams.get("isArchived") === "true");

  const {
    data: quests = [],
    isLoading,
    error,
  } = useQuery<QuestWithId[]>({
    queryKey: ["quests", questsQuery],
    queryFn: () => getQuests(questsQuery, isArchived),
    refetchInterval: 60_000,
    refetchOnWindowFocus: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateQuestNodes(quests));
  }, [dispatch, quests]);

  const store = useStoreApi();

  useEffect(() => {
    const interval = setInterval(() => {
      const { getNodes, edges } = store.getState();
      const nodes = getNodes();

      putCachedQuests({ nodes, edges });
    }, 30_000);

    return () => clearInterval(interval);
  }, [store]);

  return (
    <QuestsContext.Provider value={{ updateQuestsQuery: setQuestsQuery, quests, isLoading, error }}>
      {children}
    </QuestsContext.Provider>
  );
}

export function useQuestsProvider() {
  const context = useContext(QuestsContext);

  if (context == null) {
    throw new Error("useQuestsProvider used outside of QuestsProvider");
  }

  return context;
}
