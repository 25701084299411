import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { DeployEnvironment, deployQuest as deployQuestAsync } from "../../api/quests/quests";
import { useNodeTypesProvider } from "../../context/reactflow/NodeTypesProvider";
import useExportRuntimeData from "../useExportRuntimeData";

interface DeployQuest {
  questId: string;
  environment: DeployEnvironment;
}

export default function useDeployQuest() {
  const { exportQuestNodes } = useExportRuntimeData();

  const toast = useToast();

  const { nodeTypes } = useNodeTypesProvider();

  const { isPending: isDeploying, mutate: deployQuest } = useMutation({
    mutationFn: ({ questId, environment }: DeployQuest) =>
      deployQuestAsync(questId, environment, exportQuestNodes, nodeTypes),
    onSuccess: () => {
      toast({ title: "Quest deploy successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest deploy unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isDeploying,
    deployQuest,
  };
}
