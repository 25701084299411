import { Center, ChakraProps, Text } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { useQuestPointerProvider } from "../../context/QuestPointerProvider";

interface QuestPointerUpdateTargetProps extends ChakraProps {
  nodeId: string;
}

function QuestPointerUpdateTarget({ nodeId }: QuestPointerUpdateTargetProps) {
  const { updateSelectedQuestPointer } = useQuestPointerProvider();

  const handleUpdateSelectedQuestPointer = useCallback(
    () => updateSelectedQuestPointer(nodeId),
    [updateSelectedQuestPointer, nodeId]
  );

  return (
    <Center p={4} onClick={handleUpdateSelectedQuestPointer}>
      <Text color={"white"} casing={"uppercase"}>
        Click to move pointer here
      </Text>
    </Center>
  );
}

export default memo(QuestPointerUpdateTarget);
