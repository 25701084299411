import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { duplicateQuest as duplicateQuestAsync } from "../../api/quests/quests";
import { useNodeTypesProvider } from "../../context/reactflow/NodeTypesProvider";
import { useCopyAndPaste } from "../useCopyAndPaste";
import useExportRuntimeData from "../useExportRuntimeData";

export default function useDuplicateQuest() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { createDuplicates } = useCopyAndPaste();
  const { exportQuestNodes } = useExportRuntimeData();

  const { nodeTypes } = useNodeTypesProvider();

  const { isPending: isDuplicating, mutate: duplicateQuest } = useMutation({
    mutationFn: (questId: string) => duplicateQuestAsync(questId, createDuplicates, exportQuestNodes, nodeTypes),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["quest"],
      });

      toast({ title: "Quest duplication successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest duplication unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isDuplicating,
    duplicateQuest,
  };
}
