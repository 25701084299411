import { Box, ChakraProps, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { SpeakerPassageWithId } from "@worldwidewebb/quest-shared/dist/dialog";
import { memo } from "react";

interface OverheadDialogPreviewProps extends ChakraProps {
  speakerPassages: SpeakerPassageWithId[];
}

function OverheadDialogPreview({ speakerPassages, ...chakraProps }: OverheadDialogPreviewProps) {
  const { color, maxW = "md" } = chakraProps;

  if (speakerPassages.length === 0) {
    return null;
  }

  return (
    <Box {...chakraProps}>
      <FormControl>
        <FormLabel>
          <Text color={color} casing={"uppercase"}>
            NPC Dialog Passages
          </Text>
        </FormLabel>

        <Stack>
          {speakerPassages.map(({ passageId, passage }) => (
            <Text key={passageId} color={"white"} whiteSpace={"pre-wrap"} maxW={maxW}>
              {passage}
            </Text>
          ))}
        </Stack>
      </FormControl>
    </Box>
  );
}

export default memo(OverheadDialogPreview);
