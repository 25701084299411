import { useQuery } from "@tanstack/react-query";
import { QuestPointer } from "@worldwidewebb/client-quests";
import { getQuestPointersForUser } from "../../api/quests/questPointers";

export default function useQuestPointers(questId?: string, userId?: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: questPointers,
  } = useQuery<QuestPointer[]>({
    queryKey: ["questPointer", questId, userId],
    queryFn: () => getQuestPointersForUser(questId, userId),
    enabled: !!questId && !!userId,
    refetchInterval: 10_000,
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    questPointers,
  };
}
