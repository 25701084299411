import { useCallback } from "react";
import { NodeType } from "../models/nodeType";

const useNodeTypeNameLookup = () => {
  const getLatestType = useCallback(
    (nodeType: NodeType | undefined, nodeName: string, currentType: string = "BaseNode") => {
      let type = currentType;

      if (nodeType?.nodeClass === "flow") {
        type = "FlowNode";
      }

      if (nodeType?.nodeClass === "start") {
        type = "StartNode";
      }

      if (nodeType?.nodeClass === "end") {
        type = "EndNode";
      }

      if (nodeType?.nodeCategory === "Subgraph" || nodeType?.nodeCategory === "Subgraph Template") {
        type = "SubgraphNode";
      }

      if (nodeName === "subgraph_input") {
        type = "SubgraphInputNode";
      }

      if (nodeName === "location") {
        type = "LocationNode";
      }

      if (nodeName === "item_name_and_quantity") {
        type = "ItemNameAndQuantityNode";
      }

      if (nodeName === "dynamic_item_name_and_quantity") {
        type = "DynamicItemNameAndQuantityNode";
      }

      if (nodeName === "dynamic_location") {
        type = "DynamicLocationNode";
      }

      if (nodeName === "dynamic_npc") {
        type = "DynamicNpcNode";
      }

      if (nodeName === "npc") {
        type = "NpcNode";
      }

      if (nodeName === "dialog") {
        type = "DialogNode";
      }

      if (nodeName === "start_by_chatting_to_npc") {
        type = "StartByChattingToNpcNode";
      }

      if (nodeName === "start_with_npc_at_location") {
        type = "StartWithNpcAtLocationNode";
      }

      if (nodeName === "start_if_player_at_location") {
        type = "StartIfPlayerAtLocationNode";
      }

      if (nodeName === "start_with_spawned_entity") {
        type = "SpawnEntityAtLocationNode";
      }

      if (nodeName === "create_quest_log") {
        type = "CreateQuestLogNode";
      }

      if (nodeName === "complete_quest_log") {
        type = "CompleteQuestLogNode";
      }

      if (nodeName === "create_quest_task") {
        type = "CreateQuestTaskNode";
      }

      if (nodeName === "complete_quest_task") {
        type = "CompleteQuestTaskNode";
      }

      if (nodeName === "wait_for_player_location") {
        type = "WaitForPlayerLocationNode";
      }

      if (nodeName === "split") {
        type = "SplitNode";
      }

      if (nodeName === "join") {
        type = "JoinNode";
      }

      if (nodeName === "switch") {
        type = "SwitchNode";
      }

      if (nodeName === "boolean") {
        type = "BooleanNode";
      }

      if (nodeName === "number") {
        type = "NumberNode";
      }

      if (nodeName === "boolean_operator") {
        type = "BooleanOperatorNode";
      }

      if (nodeName === "relational_operator") {
        type = "RelationalOperatorNode";
      }

      if (nodeName === "additive_operator") {
        type = "AdditiveOperatorNode";
      }

      if (nodeName === "multiplicative_operator") {
        type = "MultiplicativeOperatorNode";
      }

      if (nodeName === "npc_move_to_player") {
        type = "NpcMoveToPlayerNode";
      }

      if (nodeName === "npc_move_by_xy") {
        type = "NpcMoveByXYNode";
      }

      if (nodeName === "player_move_to_npc") {
        type = "PlayerMoveToNpcNode";
      }

      if (nodeName === "vendor") {
        type = "VendorNode";
      }

      if (nodeName === "crafting_station") {
        type = "CraftingStationNode";
      }

      if (nodeName === "set_flag") {
        type = "SetFlagNode";
      }

      if (nodeName === "set_flag_to_value") {
        type = "SetFlagToValueNode";
      }

      if (nodeName === "clear_flag") {
        type = "ClearFlagNode";
      }

      if (nodeName === "flag") {
        type = "FlagNode";
      }

      if (nodeName === "player_has_completed_quest") {
        type = "PlayerHasCompletedQuestNode";
      }

      if (nodeName === "player_has_started_quest") {
        type = "PlayerHasStartedQuestNode";
      }

      if (nodeName === "camera_follow_npc") {
        type = "CameraFollowNpcNode";
      }

      if (nodeName === "camera_pan_to_location") {
        type = "CameraPanToLocationNode";
      }

      if (nodeName === "allegiance") {
        type = "AllegianceNode";
      }

      if (nodeName === "modify_player_allegiance") {
        type = "ModifyPlayerAllegianceNode";
      }

      if (nodeName === "ai_decision") {
        type = "AiDecisionNode";
      }

      if (nodeName === "player_has_equipment") {
        type = "PlayerHasEquipmentNode";
      }

      if (nodeName === "player_has_item_in_equipment_slot") {
        type = "PlayerHasItemInEquipmentSlotNode";
      }

      if (nodeName === "compare_items") {
        type = "CompareItemsNode";
      }

      if (nodeName === "wait_for_npc_interaction") {
        type = "WaitForNpcInteractionNode";
      }

      if (nodeName === "wait_for_chat_with_npc") {
        type = "WaitForChatWithNpcNode";
      }

      if (nodeName === "room") {
        type = "RoomNode";
      }

      if (nodeName === "coordinates") {
        type = "CoordinatesNode";
      }

      if (nodeName === "show_emote_on_npc") {
        type = "ShowEmoteOnNpcNode";
      }

      if (nodeName === "hide_emote_on_npc") {
        type = "HideEmoteOnNpcNode";
      }

      if (nodeName === "set_music") {
        type = "SetMusicNode";
      }

      if (nodeName === "prefetch_music") {
        type = "PrefetchMusicNode";
      }

      if (nodeName === "set_npc_context") {
        type = "SetNpcContextNode";
      }

      if (nodeName === "npc_set_sprite") {
        type = "NpcSetSpriteNode";
      }

      if (nodeName === "player_current_skill_level") {
        type = "PlayerCurrentSkillLevelNode";
      }

      if (nodeName === "door") {
        type = "DoorNode";
      }

      if (nodeName === "wait_for_entity_interaction") {
        type = "WaitForEntityInteractionNode";
      }

      if (nodeName === "start_with_sprite_object") {
        type = "StartWithSpriteObjectNode";
      }

      if (nodeName === "spawn_sprite_entity") {
        type = "SpawnSpriteEntityNode";
      }

      if (nodeName === "open_ui_element") {
        type = "OpenUIElementNode";
      }

      if (nodeName === "close_ui_element") {
        type = "CloseUIElementNode";
      }

      if (nodeName === "highlight_ui_element") {
        type = "HighlightUIElementNode";
      }

      if (nodeName === "reset_highlight_ui_element") {
        type = "ResetHighlightUIElementNode";
      }

      if (nodeName === "spawn_enemy_sequencer") {
        type = "SpawnEnemySequencerNode";
      }

      if (nodeName === "wait_for_enemy_sequencer") {
        type = "WaitForEnemySequencerNode";
      }

      if (nodeName === "player_grant_skill_experience_points") {
        type = "PlayerGrantSkillExperiencePointsNode";
      }

      if (nodeName === "player_grant_skill_level") {
        type = "PlayerGrantSkillLevelNode";
      }

      if (nodeName === "player_current_skill_experience_points") {
        type = "PlayerCurrentSkillExperiencePointsNode";
      }

      if (nodeName === "featureFlag") {
        type = "FeatureFlagNode";
      }

      if (nodeName === "set_feature_flag") {
        type = "SetFeatureFlagNode";
      }

      if (nodeName === "clear_feature_flag") {
        type = "ClearFeatureFlagNode";
      }

      if (nodeName === "camera_snap_to_location") {
        type = "CameraSnapToLocationNode";
      }

      if (nodeName === "npc_set_direction") {
        type = "NpcSetDirectionNode";
      }

      if (nodeName === "open_url") {
        type = "OpenUrlNode";
      }

      if (nodeName === "watch_player_action") {
        type = "WatchPlayerActionNode";
      }

      if (nodeName === "player_action_count") {
        type = "PlayerActionCountNode";
      }

      if (nodeName === "spawn_object") {
        type = "SpawnObjectNode";
      }

      if (nodeName === "spawn_entity") {
        type = "SpawnEntityNode";
      }

      if (nodeName === "player_unequip_equipment") {
        type = "PlayerUnequipEquipmentNode";
      }

      if (nodeName === "npc_unequip_equipment") {
        type = "NpcUnequipEquipmentNode";
      }

      if (nodeName === "prefetch_sfx") {
        type = "PrefetchSfxNode";
      }

      if (nodeName === "play_sfx") {
        type = "PlaySfxNode";
      }

      if (nodeName === "play_sfx_positional") {
        type = "PlaySfxPositionalNode";
      }

      if (nodeName === "prefetch_ambience") {
        type = "PrefetchAmbienceNode";
      }

      if (nodeName === "play_ambience") {
        type = "PlayAmbienceNode";
      }

      if (nodeName === "play_ambience_positional") {
        type = "PlayAmbiencePositionalNode";
      }

      if (nodeName === "npc_face_npc") {
        type = "NpcFaceNpcNode";
      }

      if (nodeName === "npc_face_player") {
        type = "NpcFacePlayerNode";
      }

      if (nodeName === "allegiance_and_amount") {
        type = "AllegianceAndAmountNode";
      }

      if (nodeName === "skill_and_experience_points") {
        type = "SkillAndExperiencePointsNode";
      }

      if (nodeName === "skill_and_levels") {
        type = "SkillAndLevelsNode";
      }

      if (nodeName === "mod") {
        type = "ModNode";
      }

      if (nodeName === "set_lut") {
        type = "SetLutNode";
      }

      if (nodeName === "complete_quest") {
        type = "CompleteQuestNode";
      }

      if (nodeName === "show_tutorial") {
        type = "ShowTutorialNode";
      }

      if (nodeName === "take_item") {
        type = "TakeItemNode";
      }

      if (nodeName === "give_item") {
        type = "GiveItemNode";
      }

      if (nodeName === "npc_move_to_location") {
        type = "NpcMoveToLocationNode";
      }

      if (nodeName === "change_pointer_priority") {
        type = "ChangePointerPriorityNode";
      }

      if (nodeName === "map_entity") {
        type = "MapEntityNode";
      }

      if (nodeName === "start_cinematic") {
        type = "StartCinematicNode";
      }

      if (nodeName === "custom_item") {
        type = "CustomItemNode";
      }

      if (nodeName === "energy_reward") {
        type = "EnergyRewardNode";
      }

      if (nodeName === "loot_table_reward") {
        type = "LootTableRewardNode";
      }

      if (nodeName === "grant_reward") {
        type = "GrantRewardNode";
      }

      return type;
    },
    []
  );

  return {
    getLatestType,
  };
};

export default useNodeTypeNameLookup;
