import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useSearchParamsState<T extends string | number | boolean>(
  name: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(initialValue);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const searchParamsValue = searchParams.get(name);

    if (searchParamsValue === String(value)) {
      return;
    }

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set(name, String(value));

    setSearchParams(updatedSearchParams);
  }, [searchParams, setSearchParams, name, value]);

  return [value, setValue];
}
