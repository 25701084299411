import { Box, ChakraProps, Stack } from "@chakra-ui/react";
import { memo, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { Node } from "reactflow";
import { useQuestSearchParamsProvider } from "../../context/quest/QuestSearchParamsProvider";
import useQuestPointers from "../../hooks/quests/useQuestPointers";
import useContainerHeight from "../../hooks/useContainerHeight";
import { NodeType } from "../../models/nodeType";
import QuestPointerContainer from "./QuestPointerContainer";

interface QuestPointerContainerListProps extends ChakraProps {
  nodes: Node<NodeType>[];
}

function QuestPointerContainerList({ color, nodes }: QuestPointerContainerListProps) {
  const { id: questId } = useParams();
  const { userId } = useQuestSearchParamsProvider();

  const { showQuestPointers } = useQuestSearchParamsProvider();
  const { questPointers = [] } = useQuestPointers(questId, userId);

  const ownQuestPointers = useMemo(
    () => questPointers.filter(({ nodeId }) => nodes.some(({ id }) => id === nodeId)),
    [questPointers, nodes]
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerRefDependencies = [showQuestPointers, questPointers];
  const containerHeight = useContainerHeight(containerRef, containerRefDependencies);

  if (!showQuestPointers) {
    return null;
  }

  return (
    <Box position={"absolute"} ref={containerRef} top={`-${containerHeight}px`} left={0} right={0}>
      <Stack spacing={0}>
        {ownQuestPointers.map(({ nodeId }) => (
          <QuestPointerContainer key={nodeId} color={color} nodeId={nodeId} position={"relative"} top={"unset"} />
        ))}
      </Stack>
    </Box>
  );
}

export default memo(QuestPointerContainerList);
