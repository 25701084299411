import React, { memo, useCallback } from "react";
import { Stack } from "@chakra-ui/react";
import { NodeProps } from "reactflow";
import { HandleCategory, NodeData, NodeType } from "../../../../models/nodeType";
import { BaseNodeWithChildren } from "./BaseNode";
import { useUpdateNodeData } from "../../../../hooks/useUpdateNodeData";
import { useUpdateNodeHandles } from "../../../../hooks/useUpdateNodeHandles";
import SelectHandleName from "../../../base/SelectHandleName";
import { useNodeTypesProvider } from "../../../../context/reactflow/NodeTypesProvider";

interface SubgraphInput extends NodeData {
  handleName: string;
  handleLabel: string;
}

const SubgraphInputNode: React.FC<NodeProps<NodeType<SubgraphInput>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = { handleName: "", handleLabel: "" } },
  } = props;

  const { handleName } = nodeData;

  const { nodeTypes } = useNodeTypesProvider();
  const { updateNodeData } = useUpdateNodeData<SubgraphInput>(nodeId);
  const { updateNodeTargetHandles, updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const findHandleLabel = useCallback(
    (handleName: string) => {
      const [_, handleLabel] =
        nodeTypes
          .flatMap(({ sourceHandles = [] }) =>
            sourceHandles.map(({ handleName, label: handleLabel = handleName }) => [handleName, handleLabel])
          )
          .find(([value]) => value === handleName) ?? [];

      return handleLabel;
    },
    [nodeTypes]
  );

  const handleUpdateHandles = useCallback(
    (handleName: string) => {
      const label = findHandleLabel(handleName);

      let handleCategory: HandleCategory = "data";

      switch (handleName) {
        case "questId": {
          handleCategory = "questId";
        }
      }

      updateNodeData({ handleName, handleLabel: label });
      updateNodeTargetHandles(handleName ? [{ handleType: "target", handleName, label, handleCategory }] : []);
      updateNodeSourceHandles(handleName ? [{ handleType: "source", handleName, label, handleCategory }] : []);
    },
    [findHandleLabel, updateNodeData, updateNodeTargetHandles, updateNodeSourceHandles]
  );

  return (
    <BaseNodeWithChildren {...props}>
      <Stack p={2}>
        <SelectHandleName color={color} value={handleName} setValue={handleUpdateHandles} textTransform={"uppercase"} />
      </Stack>
    </BaseNodeWithChildren>
  );
};

export default memo(SubgraphInputNode);
