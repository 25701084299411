import { FormControl, FormLabel, InputProps, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React, { ForwardedRef, PropsWithChildren } from "react";

// TODO: VERIFICATION PENDING

interface FormRadioProps extends PropsWithChildren, InputProps {
  values: Record<string, string>;
  disabled?: boolean;
}

function FormRadio(props: FormRadioProps, ref: ForwardedRef<HTMLInputElement>) {
  const { values, value, defaultValue, disabled, children, color, colorScheme, ...inputProps } = props;

  return (
    <FormControl>
      <FormLabel>{children}</FormLabel>

      <RadioGroup value={value?.toString()}>
        <Stack>
          {Object.entries(values).map(([value, description], index) => (
            <Radio
              key={index}
              ref={ref}
              disabled={disabled}
              color={color}
              colorScheme={colorScheme}
              {...inputProps}
              value={value}
            >
              {description}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}

export default React.forwardRef(FormRadio);
