import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from "react";
import { NodeType } from "../../models/nodeType";

interface ContextType {
  nodeTypes: NodeType[];
  getNodeType: (nodeName: string) => NodeType | undefined;
}

const Context = createContext<ContextType | null>(null);

interface NodeTypesProviderProps extends PropsWithChildren {
  nodeTypes: NodeType[];
}

export function NodeTypesProvider({ nodeTypes, children }: NodeTypesProviderProps) {
  const nodeTypesMap = useMemo(() => {
    const map = new Map<string, NodeType>();

    nodeTypes.forEach((nodeType) => {
      map.set(nodeType.nodeName, nodeType);
    });

    return map;
  }, [nodeTypes]);

  const getNodeType = useCallback(
    (nodeName: string) => {
      return nodeTypesMap.get(nodeName);
    },
    [nodeTypesMap]
  );

  return <Context.Provider value={{ nodeTypes, getNodeType }}>{children}</Context.Provider>;
}

export function useNodeTypesProvider() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useNodeTypesProvider used outside of NodeTypesProvider");
  }

  return context;
}
