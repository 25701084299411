import { PropsWithChildren, useMemo } from "react";
import { nodeTypes as staticNodeTypes } from "../../features/quest/reactflow/nodeTypes";
import useQuestSubgraphs from "../../hooks/quests/useQuestSubgraphs";
import { NodeTypesProvider } from "../reactflow/NodeTypesProvider";

export function QuestNodeTypesProvider({ children }: PropsWithChildren) {
  const { questSubgraphs } = useQuestSubgraphs();

  const nodeTypes = useMemo(
    () => [...staticNodeTypes, ...questSubgraphs.map(({ questEditorDataDefinition }) => questEditorDataDefinition)],
    [questSubgraphs]
  );

  return <NodeTypesProvider nodeTypes={nodeTypes}>{children}</NodeTypesProvider>;
}
