import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

interface ContextType {
  selectedQuestIds: string[];
  toggleSelection: (questId: string) => void;
  clearSelection: () => void;
  isSelected: (questId: string) => boolean;
}

const Context = createContext<ContextType | null>(null);

export function QuestsSelectionProvider({ children }: PropsWithChildren) {
  const [selectedQuestIds, setSelectedQuestIds] = useState<string[]>([]);

  const toggleSelection = useCallback(
    (questId: string) =>
      setSelectedQuestIds((selectedQuestIds) =>
        selectedQuestIds.includes(questId)
          ? selectedQuestIds.filter((selectedQuestId) => selectedQuestId !== questId)
          : [...selectedQuestIds, questId]
      ),
    []
  );

  const clearSelection = useCallback(() => setSelectedQuestIds([]), []);

  const isSelected = useCallback((questId: string) => selectedQuestIds.includes(questId), [selectedQuestIds]);

  return (
    <Context.Provider value={{ selectedQuestIds, toggleSelection, clearSelection, isSelected }}>
      {children}
    </Context.Provider>
  );
}

export function useQuestsSelectionProvider() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useQuestsSelectionProvider used outside of QuestsSelectionProvider");
  }

  return context;
}
