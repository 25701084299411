import { useMemo } from "react";
import { QuestPointerStatus } from "../../models/questPointerStatus";
import useQuestPointerStatuses from "./useQuestPointerStatuses";

export default function useQuestPointerStatus(nodeId: string) {
  const { isLoading, error, questPointerStatuses = [] } = useQuestPointerStatuses();

  const questPointerStatus: QuestPointerStatus | undefined = useMemo(
    () => questPointerStatuses?.find((questPointerStatus) => questPointerStatus.nodeId === nodeId),
    [questPointerStatuses, nodeId]
  );

  return {
    isLoading,
    error,
    questPointerStatus,
  };
}
