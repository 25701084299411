import { Card, CardBody, CardHeader, Container, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { memo } from "react";
import { MdOpenInNew } from "react-icons/md";
import ReactJson from "react-json-view";
import { PageSpinner } from "../components/base/PageSpinner";
import useQuestIssues, { QuestIssue } from "../hooks/questIssues/useQuestIssues";
import useQuest from "../hooks/quests/useQuest";

interface QuestIssueProps {
  questIssue: QuestIssue;
}

const QuestIssueEntry = memo(({ questIssue }: QuestIssueProps) => {
  const { quest } = useQuest(questIssue.questId);

  if (quest == null) {
    return null;
  }

  const { questId, name } = quest;

  return (
    <Card bg={"theme.dark.background"} borderRadius={0}>
      <CardHeader>
        <HStack alignContent={"center"} justifyContent={"space-between"}>
          <Text color={"indigo.600"} casing={"uppercase"}>
            {name}
          </Text>

          <Link aria-label={"Open Quest"} color={"white"} to={`/quests/${questId}`} target={"_blank"}>
            <Icon as={MdOpenInNew} />
          </Link>
        </HStack>
      </CardHeader>

      <CardBody>
        <ReactJson style={{ backgroundColor: "transparent" }} src={questIssue} theme={"google"} />
      </CardBody>
    </Card>
  );
});

function QuestIssues() {
  const { isLoading, error, questIssues } = useQuestIssues();

  if (isLoading) {
    return <PageSpinner color={"white"} />;
  }

  if (error) {
    return <PageSpinner color={"red.800"} />;
  }

  return (
    <Stack>
      <Card bg={"theme.dark.background"} borderRadius={0}>
        <CardHeader
          bg={"theme.dark.background"}
          borderBottomColor={"indigo.600"}
          borderBottomWidth={2}
          position={"sticky"}
          top={0}
        >
          <Text color={"white"} casing={"uppercase"}>
            Detected Quest Issues
          </Text>
        </CardHeader>
      </Card>

      <Stack>
        {questIssues.map((questIssue, index) => (
          <QuestIssueEntry key={index} questIssue={questIssue} />
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(QuestIssues);
