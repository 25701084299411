import { QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function createQuestEditorSession(questId: string) {
  try {
    const { data: questEditorSession } = await questEditorApi.createOwnQuestEditorSession(questId);

    return questEditorSession;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteQuestEditorSession(questId: string) {
  try {
    await questEditorApi.deleteOwnQuestEditorSession(questId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getQuestEditorSessions(questId: string) {
  try {
    const { data: questEditorSessions } = await questEditorApi.getQuestEditorSessionsForQuest(questId);

    return questEditorSessions;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
