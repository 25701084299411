import { ChakraProps, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { memo, MouseEvent, useCallback } from "react";
import { MdClose } from "react-icons/md";
import { useQuestPointerProvider } from "../../context/QuestPointerProvider";
import { QuestPointerStatus } from "../../models/questPointerStatus";
import getQuestPointerStatusColor from "../../utils/getQuestPointerStatusColor";

interface QuestPointerDeleteButtonProps extends ChakraProps {
  isSelected: boolean;
  questPointerStatus: QuestPointerStatus;
}

function QuestPointerDeleteButton({ color, isSelected, questPointerStatus }: QuestPointerDeleteButtonProps) {
  const { questPointerId } = questPointerStatus;

  const { isDeleting, deleteSelectedQuestPointer } = useQuestPointerProvider();

  const handleDeleteQuestPointer = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (questPointerId == null) {
        return;
      }

      deleteSelectedQuestPointer(questPointerId);
    },
    [deleteSelectedQuestPointer, questPointerId]
  );

  const questPointerStatusColor = getQuestPointerStatusColor(questPointerStatus) ?? color;

  return (
    <Tooltip
      label={"delete quest pointer"}
      bg={"theme.dark.background"}
      borderColor={questPointerStatusColor}
      borderWidth={2}
      color={questPointerStatusColor}
    >
      <IconButton
        aria-label={"delete quest pointer"}
        icon={<Icon as={MdClose} position={"absolute"} />}
        variant={"outline"}
        isRound={true}
        onClick={handleDeleteQuestPointer}
        isLoading={isDeleting}
        borderColor={isSelected ? "theme.dark.background" : questPointerStatusColor}
        borderWidth={2}
        color={isSelected ? "theme.dark.background" : questPointerStatusColor}
      />
    </Tooltip>
  );
}

export default memo(QuestPointerDeleteButton);
