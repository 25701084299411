import { useCallback, useEffect, useMemo } from "react";
import ReactFlow, { ControlButton, Node, ReactFlowProps } from "reactflow";
import "reactflow/dist/style.css";
import { edgeTypeDictionary } from "../components/reactFlow/edgeTypes";
import { nodeTypeDictionary } from "../components/reactFlow/nodeTypes";
import { Box, Icon, useDisclosure } from "@chakra-ui/react";
import StyledBackground from "../components/reactFlow/StyledBackground";
import StyledControls from "../components/reactFlow/StyledControls";
import StyledMiniMap from "../components/reactFlow/StyledMiniMap";
import { NodeType } from "../models/nodeType";
import { useToken } from "@chakra-ui/system";
import { BiSolidInfoCircle } from "react-icons/bi";
import { LiaHandPaperSolid, LiaHandPointer } from "react-icons/lia";
import { useUserSettingsProvider } from "../context/UserSettingsContext";
import ControlSchemeInfo from "../components/reactFlow/ControlSchemeInfo";
import { useQuestProvider } from "../context/QuestContext";
import QuestPointerToggleButton from "../components/quests/QuestPointerToggleButton";
import { useNodeTypesProvider } from "../context/reactflow/NodeTypesProvider";

const QuestEditorReadOnly = () => {
  const { quest } = useQuestProvider();
  const { nodeTypes } = useNodeTypesProvider();

  useEffect(() => {
    if (!quest?.name) {
      return;
    }

    document.title = `Quest | ${quest.name}`;

    return () => {
      document.title = "Quest Editor";
    };
  }, [quest]);

  const nodeColors = useToken(
    "colors",
    nodeTypes.map(({ color }) => color ?? "white")
  );

  const nodeColorDictionary = useMemo(
    () => Object.fromEntries(nodeTypes.map(({ color }, index) => [color ?? "white", nodeColors[index]])),
    [nodeTypes, nodeColors]
  );

  const getNodeColor = useCallback(
    (colorToken?: string) => {
      if (colorToken == null) {
        return "#FFF";
      }

      return nodeColorDictionary[colorToken];
    },
    [nodeColorDictionary]
  );

  const { controlScheme, toggleControlScheme } = useUserSettingsProvider();

  const navigationConfig: ReactFlowProps = useMemo(
    () =>
      controlScheme === "primary"
        ? {
            multiSelectionKeyCode: ["Meta", "Control"],
            panActivationKeyCode: "Shift",
            panOnDrag: [1],
            selectionKeyCode: null,
            selectionOnDrag: true,
          }
        : {
            panOnScroll: true,
            selectionKeyCode: ["Meta", "Control"],
          },
    [controlScheme]
  );

  const { onClose, onOpen, isOpen } = useDisclosure();

  return (
    <>
      <Box tabIndex={0}>
        <ReactFlow
          nodeTypes={nodeTypeDictionary}
          edgeTypes={edgeTypeDictionary}
          nodes={quest?.data?.nodes ?? []}
          edges={quest?.data?.edges ?? []}
          proOptions={{ hideAttribution: true }}
          minZoom={0.125}
          maxZoom={1}
          {...navigationConfig}
        >
          <StyledBackground />
          <StyledControls position="top-right" showInteractive={false}>
            <ControlButton onClick={onOpen}>
              <Icon as={BiSolidInfoCircle} />
            </ControlButton>
            <ControlButton onClick={toggleControlScheme}>
              <Icon as={controlScheme === "primary" ? LiaHandPointer : LiaHandPaperSolid} />
            </ControlButton>
          </StyledControls>
          <StyledMiniMap
            position="bottom-right"
            pannable={true}
            zoomable={true}
            nodeColor={(node: Node<NodeType>) => getNodeColor(node.data.color)}
          />

          <QuestPointerToggleButton />
        </ReactFlow>
      </Box>

      <ControlSchemeInfo isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default QuestEditorReadOnly;
