import React, { memo, MouseEventHandler, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Node, NodeProps, Position, useReactFlow, useStore, useStoreApi } from "reactflow";
import {
  Box,
  Card,
  CardBody,
  CardFooterProps,
  CardHeader,
  CardHeaderProps,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  MenuItem,
  MenuList,
  MenuListProps,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useToken } from "@chakra-ui/system";
import { MdDelete, MdEdit, MdSettings } from "react-icons/md";
import BaseHandle from "../../nodeHandles/base/BaseHandle";
import { BaseNodeConfigModal } from "./BaseNodeConfigModal";
import {
  getHandleCategoryColor,
  NodeData,
  NodeHandle,
  NodeType,
  SourceHandle,
  TargetHandle,
} from "../../../../models/nodeType";
import { useUpdateNodeHandles } from "../../../../hooks/useUpdateNodeHandles";
import { AiOutlineCopy } from "react-icons/ai";
import useNodeHandlesValidation from "../../../../hooks/useNodeHandlesValidation";
import ContextMenu from "../../../base/ContextMenu";
import { useConnectionProvider } from "../../../../context/ConnectionContext";
import { Subgraph } from "./SubgraphNode";
import { useForm } from "react-hook-form";
import { useUpdateNodeData } from "../../../../hooks/useUpdateNodeData";
import FormTextArea from "../../../../ui/form/FormTextArea";
import useQuestPointerStatus from "../../../../hooks/quests/useQuestPointerStatus";
import getQuestPointerStatusColor from "../../../../utils/getQuestPointerStatusColor";

interface Command {
  icon: React.ReactElement;
  name: string;
  tooltip: string;
  onClick: () => void;
}

const useCommands = (nodeId: string) => {
  const { setNodes } = useReactFlow();

  const commands: Command[] = [
    {
      name: "Delete",
      icon: <MdDelete />,
      tooltip: "delete node",
      onClick: () => {
        setNodes((nodes) => nodes.filter((node) => node.id !== nodeId));
      },
    },
  ];

  return {
    commands,
  };
};

interface BaseNodeContextMenuProps extends MenuListProps {
  commands: Command[];
}

const BaseNodeContextMenu: React.FC<BaseNodeContextMenuProps> = ({ commands, color, ...menuListProps }) => {
  return (
    <MenuList
      borderColor={color}
      borderRadius={0}
      borderWidth={1}
      bg={"theme.dark.background"}
      p={0.5}
      {...menuListProps}
    >
      {commands.map(({ icon, name, tooltip, onClick }) => (
        <Tooltip
          key={name}
          label={tooltip}
          placement={"right"}
          bg={"theme.dark.background"}
          borderWidth={1}
          borderColor={color}
          color={color}
        >
          <MenuItem bg={"theme.dark.background"} _hover={{ bg: color }} icon={icon} onClick={onClick}>
            <Text color={"white"} casing={"uppercase"}>
              {name}
            </Text>
          </MenuItem>
        </Tooltip>
      ))}
    </MenuList>
  );
};

interface BaseNodeHeaderProps extends CardHeaderProps {
  label?: string;
  color?: string;
  isReady?: boolean;
  isSelected?: boolean;
  nodeDescription?: string;
  headerButtons?: React.ReactNode;
  disableIsReadyBorder?: boolean;
}

export const BaseNodeHeader: React.FC<BaseNodeHeaderProps> = ({
  id,
  label,
  color: token,
  isReady,
  isSelected,
  nodeDescription,
  headerButtons,
  disableIsReadyBorder,
  children,
  ...cardHeaderProps
}) => {
  const [color] = useToken("colors", [token ?? "white"]);
  const toast = useToast();

  const inDevelopmentStyle: React.CSSProperties = {
    backgroundImage: "repeating-linear-gradient(-45deg, transparent, transparent 10px, #F6E05E 10px, #F6E05E 20px)",
    position: "absolute",
    left: 0,
    right: 0,
    height: "0.5rem",
  };

  const handleCopyNodeId = useCallback(async () => {
    if (id == null) {
      return;
    }

    await navigator.clipboard.writeText(id);

    toast({
      title: `Copied ID ${id} to clipboard`,
      status: "info",
    });
  }, [id]);

  return (
    <CardHeader
      bg={"theme.dark.background"}
      borderBottomWidth={1}
      borderTopWidth={8}
      borderTopColor={isSelected ? "white" : color}
      {...cardHeaderProps}
      style={{ filter: isSelected ? "drop-shadow(0px -2px 2px white)" : undefined, position: "relative" }}
    >
      <Flex style={isReady || disableIsReadyBorder ? {} : { ...inDevelopmentStyle, top: 0 }} />
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={8} justifyContent={"space-between"} flexGrow={1}>
          <Tooltip label={nodeDescription}>
            <Text color={isSelected ? "white" : color} fontWeight={700}>
              {label?.toUpperCase()}
            </Text>
          </Tooltip>

          <HStack className={"nodrag"} pl={12}>
            {id && (
              <Tooltip label={"copy ID"} bg={"theme.dark.background"} color={color} borderColor={color} borderWidth={2}>
                <IconButton
                  aria-label={"copy id"}
                  variant={"outline"}
                  isRound={true}
                  icon={<Icon as={AiOutlineCopy} position={"absolute"} />}
                  color={color}
                  borderColor={color}
                  borderWidth={2}
                  onClick={handleCopyNodeId}
                />
              </Tooltip>
            )}

            {headerButtons && headerButtons}
          </HStack>
        </Flex>
        {children && children}
      </Flex>
    </CardHeader>
  );
};

interface BaseNodeFooterProps extends CardFooterProps {
  color?: string;
  isSelected?: boolean;
}

export const BaseNodeFooter: React.FC<BaseNodeFooterProps> = ({ color: token, isSelected, ...cardFooterProps }) => {
  const [color] = useToken("colors", [token ?? "white"]);

  return (
    <Box
      bg={"theme.dark.background"}
      borderBottomWidth={1}
      borderBottomColor={isSelected ? "white" : color}
      {...cardFooterProps}
      style={{ filter: isSelected ? "drop-shadow(0px 2px 2px white)" : undefined }}
    ></Box>
  );
};

interface SourceHandleComponentProps extends PropsWithChildren {
  id?: string;
  isConnectable: boolean;
  isSelected?: boolean;
  isEditing?: boolean;
  isEditable?: boolean;
  showIsConnectable?: boolean;
  onUpdate?: (label: string) => void;
  onDelete?: () => void;
  onInsertBefore?: () => void;
  onInsertAfter?: () => void;
  label?: string;
  color?: string;
}

const SourceHandleComponent: React.FC<SourceHandleComponentProps> = ({
  id,
  isConnectable,
  isSelected,
  isEditing,
  isEditable,
  showIsConnectable,
  onUpdate,
  onDelete,
  onInsertBefore,
  onInsertAfter,
  label,
  color,
  children,
}) => {
  return (
    <BaseHandle
      id={id}
      isConnectable={isConnectable}
      isSelected={isSelected}
      isEditing={isEditing}
      isEditable={isEditable}
      showIsConnectable={showIsConnectable}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onInsertBefore={onInsertBefore}
      onInsertAfter={onInsertAfter}
      type={"source"}
      position={Position.Right}
      label={label}
      color={color}
    >
      {children && children}
    </BaseHandle>
  );
};

interface TargetHandleComponentProps {
  id?: string;
  isConnectable: boolean;
  isSelected?: boolean;
  isEditing?: boolean;
  isEditable?: boolean;
  showIsConnectable?: boolean;
  onUpdate?: (label: string) => void;
  onDelete?: () => void;
  onInsertBefore?: () => void;
  onInsertAfter?: () => void;
  label?: string;
  color?: string;
  onDoubleClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

const TargetHandleComponent: React.FC<TargetHandleComponentProps> = ({
  id,
  isConnectable,
  isSelected,
  isEditing,
  isEditable,
  showIsConnectable,
  onUpdate,
  onDelete,
  onInsertBefore,
  onInsertAfter,
  label,
  color,
  onDoubleClick,
}) => {
  return (
    <BaseHandle
      id={id}
      isConnectable={isConnectable}
      isSelected={isSelected}
      isEditing={isEditing}
      isEditable={isEditable}
      showIsConnectable={showIsConnectable}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onInsertBefore={onInsertBefore}
      onInsertAfter={onInsertAfter}
      type={"target"}
      position={Position.Left}
      label={label}
      color={color}
      onDoubleClick={onDoubleClick}
    />
  );
};

const BaseNode: React.FC<NodeProps<NodeType>> = (props) => {
  return <BaseNodeWithChildren {...props} />;
};

export interface DoubleClickTargetHandleConnectConfig {
  sourceNodeName: string;
  targetHandleName: string;
}

interface BaseNodeWithChildrenProps extends NodeProps<NodeType<NodeData & Partial<Subgraph>>>, PropsWithChildren {
  overview?: React.ReactNode;
  header?: React.ReactNode;
  headerButtons?: React.ReactNode;
  disableIsReadyBorder?: boolean;
  doubleClickTargetHandleConnectConfigs?: DoubleClickTargetHandleConnectConfig[];
}

export const BaseNodeWithChildren: React.FC<BaseNodeWithChildrenProps> = ({
  id: nodeId,
  isConnectable,
  selected: isSelected,
  data: {
    label,
    color,
    targetHandles,
    sourceHandles,
    isEditable,
    isTargetHandlesEditable,
    isSourceHandlesEditable,
    isReady,
    isReadyForTemplating,
    nodeClass,
    nodeData,
    nodeDescription,
    nodeCategory,
  },
  children,
  overview,
  header,
  headerButtons,
  disableIsReadyBorder,
  doubleClickTargetHandleConnectConfigs,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { getValidConnectionHandles } = useNodeHandlesValidation();

  const { selectedHandleId, selectedHandleType } = useConnectionProvider();

  const initiatingConnectionHandleId = useStore((state) => state.connectionHandleId);
  const initiatingConnectionHandleType = useStore((state) => state.connectionHandleType);
  const validConnectionHandles = getValidConnectionHandles(
    nodeId,
    initiatingConnectionHandleId || selectedHandleId || null,
    (initiatingConnectionHandleId != null ? initiatingConnectionHandleType : null) || selectedHandleType
  );

  const validConnectionHandleIds = validConnectionHandles.map(({ handleId }) => handleId);

  const { updateNodeTargetHandles, updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const hasSourceOrTargetHandles = sourceHandles?.length !== 0 || targetHandles?.length !== 0;

  useEffect(() => {
    if (isSelected) {
      return;
    }

    setIsEditing(false);
  }, [isSelected]);

  const handleUpdateTargetHandle = useCallback(
    (index: number, label: string) => {
      if (!label.trim()) {
        return;
      }

      if (targetHandles == null) {
        return;
      }

      const targetHandlesCloned = structuredClone(targetHandles);

      targetHandlesCloned.at(index).label = label;
      // only update the label for now using this interface
      // targetHandlesCloned.at(index).handleName = label.toLowerCase().replaceAll(" ", "_");

      updateNodeTargetHandles(targetHandlesCloned);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const handleUpdateSourceHandle = useCallback(
    (index: number, label: string) => {
      if (!label.trim()) {
        return;
      }

      if (sourceHandles == null) {
        return;
      }

      const sourceHandlesCloned = structuredClone(sourceHandles);

      sourceHandlesCloned.at(index).label = label;
      // only update the label for now using this interface
      // sourceHandlesCloned.at(index).handleName = label.toLowerCase().replaceAll(" ", "_");

      updateNodeSourceHandles(sourceHandlesCloned);
    },
    [sourceHandles, updateNodeSourceHandles]
  );

  const handleDeleteTargetHandle = useCallback(
    (index: number) => {
      if (targetHandles == null) {
        return;
      }

      const targetHandlesCloned = structuredClone(targetHandles);

      targetHandlesCloned.splice(index, 1);

      updateNodeTargetHandles(targetHandlesCloned);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const handleDeleteSourceHandle = useCallback(
    (index: number) => {
      if (sourceHandles == null) {
        return;
      }

      const sourceHandlesCloned = structuredClone(sourceHandles);

      sourceHandlesCloned.splice(index, 1);

      updateNodeSourceHandles(sourceHandlesCloned);
    },
    [sourceHandles, updateNodeSourceHandles]
  );

  const handleInsertTargetHandle = useCallback(
    (index: number, targetHandle: TargetHandle) => {
      if (targetHandles == null) {
        return;
      }

      const targetHandlesCloned = structuredClone(targetHandles);

      const targetHandleCloned = structuredClone(targetHandle);
      targetHandleCloned.handleId = undefined;

      updateNodeTargetHandles([
        ...targetHandlesCloned.slice(0, index),
        targetHandleCloned,
        ...targetHandlesCloned.slice(index),
      ]);
    },
    [targetHandles, updateNodeTargetHandles]
  );

  const handleInsertSourceHandle = useCallback(
    (index: number, sourceHandle: SourceHandle) => {
      if (sourceHandles == null) {
        return;
      }

      const sourceHandlesCloned = structuredClone(sourceHandles);

      const sourceHandleCloned = structuredClone(sourceHandle);
      sourceHandleCloned.handleId = undefined;

      updateNodeSourceHandles([
        ...sourceHandlesCloned.slice(0, index),
        sourceHandleCloned,
        ...sourceHandlesCloned.slice(index),
      ]);
    },
    [sourceHandles, updateNodeSourceHandles]
  );

  const { commands } = useCommands(nodeId);

  const isTemplate = nodeData?.isTemplate ?? false;
  const isSubgraph = nodeCategory === "Subgraph" || nodeCategory === "Subgraph Template";
  const templateData = nodeData?.templateData;
  const templateDescription = templateData?.description ?? "";

  const { register, reset, handleSubmit } = useForm<{ isTemplate: boolean; templateDescription: string }>({
    defaultValues: useMemo(
      () => ({
        isTemplate,
        templateDescription,
      }),
      [isTemplate, templateDescription]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<NodeData>(nodeId);

  const onHandleSubmit = useCallback(
    ({ isTemplate, templateDescription }: { isTemplate: boolean; templateDescription: string }) => {
      const description = isTemplate ? templateDescription : "";

      updateNodeData({
        isTemplate,
        templateData: {
          ...nodeData?.templateData,
          description,
        },
      });

      reset({
        isTemplate,
        templateDescription: description,
      });
    },
    [updateNodeData, reset, nodeData]
  );

  const store = useStoreApi();

  const { onConnect } = useConnectionProvider();

  const handleTargetHandleDoubleClick = useCallback(
    ({ handleId, handleName }: TargetHandle) => {
      doubleClickTargetHandleConnectConfigs?.forEach(({ sourceNodeName, targetHandleName }) => {
        if (targetHandleName !== handleName) {
          return;
        }

        const edges = store.getState().edges;
        const nodes = store.getState().getNodes();

        if (edges.some(({ targetHandle }) => targetHandle === handleId)) {
          return;
        }

        const startNodeWithNpc: Node<NodeType> | undefined = nodes.find(
          ({ data: { nodeName } }) => nodeName === sourceNodeName
        );

        if (startNodeWithNpc == null) {
          return;
        }

        const startNodeWithNpcSourceHandle = startNodeWithNpc.data.sourceHandles?.find(
          (sourceHandle) => sourceHandle.handleName === handleName
        );

        if (startNodeWithNpcSourceHandle == null) {
          return;
        }

        onConnect({
          source: startNodeWithNpc.id,
          sourceHandle: startNodeWithNpcSourceHandle.handleId ?? null,
          target: nodeId,
          targetHandle: handleId ?? null,
        });
      });
    },
    [doubleClickTargetHandleConnectConfigs, store, onConnect, nodeId]
  );

  return (
    <>
      <BaseNodeContainer nodeId={nodeId} isEditing={isEditable}>
        <Card bg={"theme.dark.background"} borderRadius={0} minW={"20rem"}>
          <ContextMenu renderMenu={() => <BaseNodeContextMenu commands={commands} color={color} zIndex={2000} />}>
            {(ref) => (
              <Box ref={ref}>
                <BaseNodeHeader
                  id={nodeId}
                  label={label}
                  color={color}
                  isSelected={isSelected}
                  isReady={isReady}
                  nodeDescription={nodeDescription}
                  headerButtons={headerButtons}
                  disableIsReadyBorder={disableIsReadyBorder}
                >
                  <Flex gap={2}>
                    {isEditing && (
                      <IconButton
                        size={"sm"}
                        variant={"ghost"}
                        color={isSelected ? "white" : color}
                        icon={<Icon as={MdSettings} />}
                        aria-label={"settings"}
                        onClick={onOpen}
                      />
                    )}
                    {isEditable && (
                      <IconButton
                        size={"sm"}
                        variant={"ghost"}
                        color={isSelected ? "white" : color}
                        bg={isEditing ? "whiteAlpha.200" : undefined}
                        icon={<Icon as={MdEdit} />}
                        aria-label={"edit"}
                        onClick={() => setIsEditing((isEditing) => !isEditing)}
                      />
                    )}
                  </Flex>
                </BaseNodeHeader>
              </Box>
            )}
          </ContextMenu>

          <Stack className={"nodrag"} cursor={"default"} p={3}>
            {header && header}
          </Stack>

          {hasSourceOrTargetHandles && (
            <Flex gap={2} py={2} justifyContent={"space-between"}>
              <Flex gap={0.25} direction={"column"}>
                {targetHandles &&
                  targetHandles.map((targetHandle, index) => {
                    const { handleId, handleCategory, label } = targetHandle;

                    return (
                      <TargetHandleComponent
                        key={index}
                        id={handleId}
                        isConnectable={isConnectable}
                        isSelected={isSelected}
                        isEditing={isEditing}
                        isEditable={isTargetHandlesEditable}
                        showIsConnectable={validConnectionHandleIds.includes(handleId)}
                        onUpdate={(label: string) => handleUpdateTargetHandle(index, label)}
                        onDelete={() => handleDeleteTargetHandle(index)}
                        onInsertBefore={() => handleInsertTargetHandle(index, targetHandle)}
                        onInsertAfter={() => handleInsertTargetHandle(index + 1, targetHandle)}
                        label={label}
                        color={getHandleCategoryColor(handleCategory, isReady)}
                        onDoubleClick={() => handleTargetHandleDoubleClick(targetHandle)}
                      />
                    );
                  })}
              </Flex>
              <Flex gap={0.25} direction={"column"}>
                {sourceHandles &&
                  sourceHandles.map((sourceHandle, index) => {
                    const { handleId, handleCategory, label } = sourceHandle;

                    return (
                      <SourceHandleComponent
                        key={index}
                        id={handleId}
                        isConnectable={isConnectable}
                        isSelected={isSelected}
                        isEditing={isEditing}
                        isEditable={isSourceHandlesEditable}
                        showIsConnectable={validConnectionHandleIds.includes(handleId)}
                        onUpdate={(label: string) => handleUpdateSourceHandle(index, label)}
                        onDelete={() => handleDeleteSourceHandle(index)}
                        onInsertBefore={() => handleInsertSourceHandle(index, sourceHandle)}
                        onInsertAfter={() => handleInsertSourceHandle(index + 1, sourceHandle)}
                        label={label}
                        color={getHandleCategoryColor(handleCategory, isReady)}
                      />
                    );
                  })}
              </Flex>
            </Flex>
          )}

          {children && (
            <CardBody p={3}>
              {overview}
              {children}
            </CardBody>
          )}

          {!isSubgraph && isReadyForTemplating && (
            <form onSubmit={handleSubmit(onHandleSubmit)} onBlur={handleSubmit(onHandleSubmit)}>
              <Divider />

              <Stack className={"nodrag"} p={4}>
                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      Is Template Node
                    </Text>
                  </FormLabel>

                  <Checkbox color={color} {...register("isTemplate")} />
                </FormControl>

                {isTemplate && (
                  <FormTextArea color={color} {...register("templateDescription")}>
                    <Text color={color} casing={"uppercase"}>
                      Template Description
                    </Text>
                  </FormTextArea>
                )}
              </Stack>
            </form>
          )}

          <BaseNodeFooter color={color} isSelected={isSelected} />
        </Card>
      </BaseNodeContainer>

      <BaseNodeConfigModal
        isOpen={isOpen}
        onClose={onClose}
        targetHandles={targetHandles}
        sourceHandles={sourceHandles}
        onUpdateTargetHandles={updateNodeTargetHandles}
        onUpdateSourceHandles={updateNodeSourceHandles}
        color={color}
      />
    </>
  );
};

interface BaseNodeContainerProps extends PropsWithChildren {
  nodeId: string;
  isEditing?: boolean;
}

const BaseNodeContainer = memo(({ nodeId, isEditing, children }: BaseNodeContainerProps) => {
  const { questPointerStatus } = useQuestPointerStatus(nodeId);

  const questPointerColor = getQuestPointerStatusColor(questPointerStatus);

  const { setNodes } = useReactFlow();

  const handleDeselectNode = useCallback(() => {
    if (isEditing) {
      return;
    }

    setNodes((nodes) => {
      const node = nodes.find(({ id }) => id === nodeId);

      if (node == null) {
        return nodes;
      }

      node.selected = false;

      return nodes;
    });
  }, [setNodes, nodeId, isEditing]);

  return (
    <Flex
      p={0}
      gap={1}
      direction={"column"}
      borderColor={questPointerColor}
      borderWidth={questPointerColor ? 3 : 0}
      onKeyDown={handleDeselectNode}
    >
      {children}
    </Flex>
  );
});

export default memo(BaseNode);
