import { Center, ChakraProps, HStack, Stack, Text } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { useQuestPointerProvider } from "../../context/QuestPointerProvider";
import { QuestPointerStatus } from "../../models/questPointerStatus";
import getQuestPointerStatusColor from "../../utils/getQuestPointerStatusColor";
import QuestPointerCopyButton from "./QuestPointerCopyButton";
import QuestPointerDeleteButton from "./QuestPointerDeleteButton";

interface QuestPointerSelectTargetProps extends ChakraProps {
  questPointerStatus: QuestPointerStatus;
}

function QuestPointerSelectTarget({ color, questPointerStatus }: QuestPointerSelectTargetProps) {
  const { questPointerId } = questPointerStatus;

  const { selectedQuestPointerId, changeSelectedQuestPointer } = useQuestPointerProvider();
  const hasSelectedQuestPointerId = selectedQuestPointerId != null;
  const isSelected = hasSelectedQuestPointerId && selectedQuestPointerId === questPointerId;

  const handleChangeSelectedQuestPointer = useCallback(() => {
    if (questPointerId == null) {
      return;
    }

    changeSelectedQuestPointer(questPointerId);
  }, [changeSelectedQuestPointer, questPointerId]);

  const questPointerStatusColor = getQuestPointerStatusColor(questPointerStatus) ?? color;

  return (
    <Center
      px={5}
      py={3}
      bg={isSelected ? questPointerStatusColor : "theme.dark.background"}
      onClick={handleChangeSelectedQuestPointer}
    >
      <HStack flexGrow={1} justifyContent={"space-between"}>
        <Stack spacing={0}>
          <Text color={isSelected ? "theme.dark.background" : questPointerStatusColor} casing={"uppercase"}>
            Quest Pointer ID
          </Text>

          <HStack>
            <Text color={isSelected ? "theme.dark.background" : questPointerStatusColor} casing={"uppercase"}>
              {questPointerId}
            </Text>

            <QuestPointerCopyButton color={color} isSelected={isSelected} questPointerStatus={questPointerStatus} />
          </HStack>
        </Stack>

        <QuestPointerDeleteButton color={color} isSelected={isSelected} questPointerStatus={questPointerStatus} />
      </HStack>
    </Center>
  );
}

export default memo(QuestPointerSelectTarget);
