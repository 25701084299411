import { Box, Center, ChakraProps, Spinner } from "@chakra-ui/react";
import { memo, ReactElement, useRef } from "react";
import { useQuestSearchParamsProvider } from "../../context/quest/QuestSearchParamsProvider";
import { useQuestPointerProvider } from "../../context/QuestPointerProvider";
import useQuestPointerStatus from "../../hooks/quests/useQuestPointerStatus";
import useContainerHeight from "../../hooks/useContainerHeight";
import getQuestPointerStatusColor from "../../utils/getQuestPointerStatusColor";
import QuestPointerCreateTarget from "./QuestPointerCreateTarget";
import QuestPointerSelectTarget from "./QuestPointerSelectTarget";
import QuestPointerUpdateTarget from "./QuestPointerUpdateTarget";

function QuestPointerError() {
  return (
    <Center p={4}>
      <Spinner color={"red.800"} />
    </Center>
  );
}

interface QuestPointerContainerProps extends ChakraProps {
  nodeId: string;
}

function QuestPointerContainer({ color, nodeId, ...chakraProps }: QuestPointerContainerProps) {
  const { showQuestPointers } = useQuestSearchParamsProvider();
  const { isLoading, error, questPointerStatus } = useQuestPointerStatus(nodeId);

  const { selectedQuestPointerId } = useQuestPointerProvider();
  const hasSelectedQuestPointerId = selectedQuestPointerId != null;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerRefDependencies = [showQuestPointers, isLoading, error, questPointerStatus, hasSelectedQuestPointerId];
  const containerHeight = useContainerHeight(containerRef, containerRefDependencies);

  let questPointerContainerContent: ReactElement | undefined = undefined;

  if (questPointerStatus) {
    questPointerContainerContent = <QuestPointerSelectTarget color={color} questPointerStatus={questPointerStatus} />;
  }

  if (questPointerStatus == null && hasSelectedQuestPointerId) {
    questPointerContainerContent = <QuestPointerUpdateTarget color={color} nodeId={nodeId} />;
  }

  if (error) {
    questPointerContainerContent = <QuestPointerError />;
  }

  if (questPointerContainerContent == null) {
    questPointerContainerContent = <QuestPointerCreateTarget color={color} nodeId={nodeId} />;
  }

  if (!showQuestPointers) {
    return null;
  }

  const questPointerStatusColor = getQuestPointerStatusColor(questPointerStatus) ?? color;

  return (
    <Box position={"absolute"} ref={containerRef} top={`-${containerHeight}px`} left={0} right={0} {...chakraProps}>
      <Box bg={"theme.dark.background"} borderColor={questPointerStatusColor} borderWidth={2} mb={2}>
        {questPointerContainerContent}
      </Box>
    </Box>
  );
}

export default memo(QuestPointerContainer);
