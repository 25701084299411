import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import SelectNpcIndicator from "../../base/SelectNpcIndicator";
import FlowNode from "./FlowNode";
import { FormControl, FormLabel, NumberInput, NumberInputField, Stack, Text } from "@chakra-ui/react";
import { SpeakerPassageWithId } from "@worldwidewebb/quest-shared/dist/dialog";
import OverheadDialogConfigurationModal from "../../../ui/dialog/OverheadDialogConfigurationModal";
import OverheadDialogPreview from "../../../ui/dialog/OverheadDialogPreview";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Form } from "react-router-dom";

interface WaitForNpcInteraction extends NodeData {
  npcIndicator: string;
  speakerPassages: SpeakerPassageWithId[];
  radius: number;
}

function WaitForNpcInteractionNode(props: NodeProps<NodeType<WaitForNpcInteraction>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const npcIndicator = nodeData.npcIndicator ?? "none";
  const speakerPassages = nodeData.speakerPassages ?? [];
  const radius = nodeData.radius ?? 100;

  const { control, getValues, setValue, handleSubmit } = useForm<WaitForNpcInteraction>({
    defaultValues: useMemo(
      () => ({
        npcIndicator,
        radius,
      }),
      [npcIndicator, radius]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<WaitForNpcInteraction>(nodeId);

  return (
    <FlowNode {...props}>
      <Stack>
        <Form onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
          <Stack>
            <SelectNpcIndicator
              color={color}
              value={getValues("npcIndicator")}
              setValue={(value) => setValue("npcIndicator", value)}
            />

            <OverheadDialogPreview color={color} speakerPassages={speakerPassages} />

            <FormControl>
              <FormLabel>
                <Text color={color} casing={"uppercase"}>
                  NPC Proximity Radius
                </Text>
              </FormLabel>

              <Controller
                name={"radius"}
                control={control}
                render={({ field: { ref, name, value, onBlur, onChange } }) => (
                  <NumberInput ref={ref} name={name} value={value} onBlur={onBlur} onChange={onChange}>
                    <NumberInputField color={color} borderColor={color} borderRadius={0} borderWidth={2} />
                  </NumberInput>
                )}
              />
            </FormControl>
          </Stack>
        </Form>
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              NPC Dialog
            </Text>
          </FormLabel>

          <Stack>
            <OverheadDialogConfigurationModal
              color={color}
              speakerId={"last_used"}
              speakerPassages={speakerPassages}
              onSubmit={updateNodeData}
            />
          </Stack>
        </FormControl>
      </Stack>
    </FlowNode>
  );
}

export default memo(WaitForNpcInteractionNode);
