import { QuestPointerStatus } from "../models/questPointerStatus";

export default function getQuestPointerStatusColor(questPointerStatus?: QuestPointerStatus) {
  if (questPointerStatus == null) {
    return undefined;
  }

  let questPointerStatusColor: string | undefined = undefined;

  const { isActive, isAwaiting, isFinished, hasError } = questPointerStatus;

  if (isActive) {
    questPointerStatusColor = "green.600";
  }

  if (isAwaiting) {
    questPointerStatusColor = "green.200";
  }

  if (isFinished) {
    questPointerStatusColor = "yellow.200";
  }

  if (hasError) {
    questPointerStatusColor = "red.800";
  }

  return questPointerStatusColor;
}
