import { Center, ChakraProps, Text } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { useQuestPointerProvider } from "../../context/QuestPointerProvider";

interface QuestPointerCreateTargetProps extends ChakraProps {
  nodeId: string;
}

function QuestPointerCreateTarget({ nodeId }: QuestPointerCreateTargetProps) {
  const { createTargetedQuestPointer } = useQuestPointerProvider();

  const handleCreateSelectedQuestPointer = useCallback(
    () => createTargetedQuestPointer(nodeId),
    [createTargetedQuestPointer, nodeId]
  );

  return (
    <Center p={4} onClick={handleCreateSelectedQuestPointer}>
      <Text color={"white"} casing={"uppercase"}>
        Click to create pointer here
      </Text>
    </Center>
  );
}

export default memo(QuestPointerCreateTarget);
