import React, { memo, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { useAsyncSearchProvider } from "./AsyncSearchProvider";
import {
  Box,
  Center,
  ChakraProps,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Spinner,
  Stack,
  StackProps,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import ScrollableBox from "./ScrollableBox";
import { MdClose } from "react-icons/md";

const SearchQuery = React.forwardRef<HTMLInputElement, InputProps>(({ color }, ref) => {
  const { searchQuery, setSearchQuery, reloadResults } = useAsyncSearchProvider();

  const handleKeyDownEnter = useCallback(
    ({ key }: React.KeyboardEvent) => {
      if (key !== "Enter" && key !== "Return") {
        return;
      }

      reloadResults();
    },
    [reloadResults]
  );

  return (
    <Input
      ref={ref}
      borderColor={color}
      borderRadius={0}
      borderWidth={2}
      color={color}
      value={searchQuery}
      onChange={({ target: { value } }) => setSearchQuery(value)}
      onKeyDown={handleKeyDownEnter}
      placeholder={"Search..."}
    />
  );
});

interface SelectedValueProps extends StackProps {
  label: string;
  onClear: () => void;
}

const SelectedValue: React.FC<SelectedValueProps> = memo(({ color, label, onClick, onClear }) => {
  return (
    <HStack
      pl={4}
      pr={2}
      py={1.5}
      justifyContent={"space-between"}
      borderColor={color}
      borderRadius={0}
      borderWidth={2}
      onClick={onClick}
    >
      {label ? (
        <Tooltip
          color={color}
          label={label}
          placement={"right"}
          bg={"theme.dark.background"}
          borderColor={color}
          borderWidth={1}
        >
          <Input
            color={color}
            value={label}
            isReadOnly={true}
            isTruncated={true}
            overflow={"hidden"}
            variant={"unstyled"}
            flexGrow={1}
          />
        </Tooltip>
      ) : (
        <Skeleton h={4} flexGrow={1} />
      )}

      <IconButton
        color={color}
        icon={<Icon as={MdClose} />}
        size={"xs"}
        variant={"ghost"}
        aria-label={"clear selected value"}
        onClick={onClear}
      />
    </HStack>
  );
});

const SearchResults: React.FC<ChakraProps & PropsWithChildren> = memo(({ color, children }) => {
  const { isLoading, hasError, hasSearchResults } = useAsyncSearchProvider();

  if (isLoading) {
    return (
      <Center p={4}>
        <Spinner color={color?.toString() ?? ""} />
      </Center>
    );
  }

  if (hasError) {
    return (
      <Center p={4}>
        <Text color={color} casing={"uppercase"}>
          Try again later
        </Text>
      </Center>
    );
  }

  if (!hasSearchResults) {
    return (
      <Center p={4}>
        <Text color={color} casing={"uppercase"}>
          No results found
        </Text>
      </Center>
    );
  }

  return <>{children}</>;
});

interface SearchResultProps extends ChakraProps {
  value: string;
  label: string;
  isSelected: boolean;
  onSelected: (value: string, label: string) => void;
}

const SearchResult: React.FC<SearchResultProps> = memo(({ color, value, label, isSelected, onSelected }) => {
  return (
    <Box
      px={4}
      py={2}
      bg={isSelected ? "whiteAlpha.200" : undefined}
      cursor={"pointer"}
      _hover={{ bg: "whiteAlpha.200" }}
      onClick={() => onSelected(value, label)}
    >
      <Tooltip
        label={label}
        placement={"right"}
        bg={"theme.dark.background"}
        borderWidth={1}
        borderColor={color}
        color={color}
      >
        <Text color={color} isTruncated={true} overflow={"hidden"}>
          {label}
        </Text>
      </Tooltip>
    </Box>
  );
});

interface AsyncSelectProps extends ChakraProps {
  title: string;
  selectedValue: string;
  setSelectedValue: (selectedValue: string) => void;
  showLabel?: boolean;
  isLoading?: boolean;
  error?: Error | null;
}

const AsyncSelect: React.FC<AsyncSelectProps> = ({
  color,
  title,
  selectedValue,
  setSelectedValue,
  showLabel = true,
  isLoading,
  error,
  ...chakraProps
}) => {
  const { searchResults } = useAsyncSearchProvider();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showSearchQuery = isOpen || !selectedValue;

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedLabel, setSelectedLabel] = useState<string>("");

  const handleUpdateSelectedValue = useCallback(
    (value: string, label: string) => {
      setSelectedValue(value);
      setSelectedLabel(label);

      onClose();
    },
    [onClose]
  );

  const handleRemoveSelectedValue = useCallback(() => {
    setSelectedValue("");
    setSelectedLabel("");

    onOpen();
  }, [onOpen]);

  const handleCloseDelayed = () => setTimeout(onClose, 400); // closeOnBlur on Popover does not work

  useEffect(() => {
    if (!selectedValue) {
      return;
    }

    const [_, label] = searchResults.find(([value]) => value === selectedValue) ?? [];

    if (!label) {
      return;
    }

    setSelectedLabel(label);
  }, [searchResults, selectedValue]);

  return (
    <Stack ref={containerRef} onBlur={handleCloseDelayed} {...chakraProps}>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} initialFocusRef={inputRef}>
        <PopoverTrigger>
          <FormControl>
            {showLabel && (
              <FormLabel>
                <Text color={color} casing={"uppercase"}>
                  {title}
                </Text>
              </FormLabel>
            )}

            {showSearchQuery ? (
              <SearchQuery color={color} ref={inputRef} />
            ) : (
              <SelectedValue color={color} label={selectedLabel} onClear={handleRemoveSelectedValue} />
            )}
          </FormControl>
        </PopoverTrigger>

        <Portal containerRef={containerRef}>
          <PopoverContent
            minW={containerRef.current?.clientWidth}
            maxW={containerRef.current?.clientWidth}
            bg={"theme.dark.background"}
            borderColor={color}
            borderRadius={0}
            borderWidth={2}
          >
            <SearchResults color={color}>
              <ScrollableBox color={color} maxH={"xs"}>
                {isOpen &&
                  searchResults.map(([value, label]) => (
                    <SearchResult
                      key={value}
                      color={color}
                      value={value}
                      label={label}
                      isSelected={value === selectedValue}
                      onSelected={handleUpdateSelectedValue}
                    />
                  ))}
              </ScrollableBox>
            </SearchResults>
          </PopoverContent>
        </Portal>
      </Popover>

      {isLoading && (
        <HStack>
          <Spinner color={color?.toString()} />

          <Text color={color} casing={"uppercase"}>
            Loading
          </Text>
        </HStack>
      )}

      {error && (
        <Center>
          <Text color={"red.800"} casing={"uppercase"}>
            {error.message}
          </Text>
        </Center>
      )}
    </Stack>
  );
};

export default memo(AsyncSelect);
