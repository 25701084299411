import {
  Button,
  Center,
  ChakraProps,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SpeakerPassage, SpeakerPassageWithId } from "@worldwidewebb/quest-shared/dist/dialog";
import { Dispatch, memo, useCallback, useEffect, useMemo } from "react";
import { useFieldArray, useForm, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { MdClose, MdDragHandle } from "react-icons/md";
import { Form } from "react-router-dom";
import { ulid } from "ulid";
import Editor from "../../components/editor/Editor";
import { useDnD } from "../../hooks/useDnD";
import Icon from "../base/chakra/Icon";
import IconButton from "../base/chakra/IconButton";

function watchSpeakerPassage(
  watch: UseFormWatch<OverheadDialogConfiguration>,
  index: number,
  speakerId: string
): SpeakerPassage {
  return {
    passageType: watch(`speakerPassages.${index}.passageType`) || "dialog",
    passage: watch(`speakerPassages.${index}.passage`),
    passagePrompt: watch(`speakerPassages.${index}.passagePrompt`),
    speakerType: watch(`speakerPassages.${index}.speakerType`) || "npc",
    speakerId: watch(`speakerPassages.${index}.speakerId`) || speakerId,
  };
}

function setSpeakerPassagePassage(
  setValue: UseFormSetValue<OverheadDialogConfiguration>,
  index: number,
  passage: string
) {
  setValue(`speakerPassages.${index}.passage`, passage);
}

interface SpeakerPassageComponentProps extends ChakraProps {
  index: number;
  speakerId: string;
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  setValue: UseFormSetValue<OverheadDialogConfiguration>;
  watch: UseFormWatch<OverheadDialogConfiguration>;
}

const SpeakerPassageComponent = memo(
  ({ index, speakerId, updateIndex, removeIndex, setValue, watch, color }: SpeakerPassageComponentProps) => {
    const { handlerId, isDragging, ref, refPreview } = useDnD("speakerPassages", index, updateIndex);
    const { passage } = watchSpeakerPassage(watch, index, speakerId);

    return (
      <Flex borderColor={color} borderRadius={0} borderWidth={1} ref={refPreview} opacity={isDragging ? 0.25 : 1}>
        <Center p={1} pl={3} ref={ref} data-handler-id={handlerId} cursor={"move"}>
          <Icon as={MdDragHandle} />
        </Center>
        <Stack flexGrow={1}>
          <Flex p={2} ml={2} bg={color} alignItems={"center"} justifyContent={"flex-end"}>
            <IconButton
              size={"xs"}
              color={"white"}
              variant={"ghost"}
              icon={<Icon as={MdClose} />}
              aria-label={"delete speaker passage"}
              onClick={() => removeIndex(index)}
            />
          </Flex>
          <Stack p={2}>
            <FormControl>
              <Editor
                borderColor={color}
                borderRadius={0}
                borderWidth={2}
                value={passage}
                onChange={(passage) => setSpeakerPassagePassage(setValue, index, passage)}
              />
            </FormControl>
          </Stack>
        </Stack>
      </Flex>
    );
  }
);

export interface OverheadDialogConfiguration {
  speakerPassages: SpeakerPassageWithId[];
}

export interface OverheadDialogConfigurationModalProps extends ChakraProps {
  speakerId: string;
  speakerPassages: SpeakerPassageWithId[];
  onSubmit: Dispatch<OverheadDialogConfiguration>;
}

function OverheadDialogConfigurationModal({
  bg = "theme.dark.background",
  color,
  speakerId,
  onSubmit,
  speakerPassages,
}: OverheadDialogConfigurationModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { control, watch, setValue, handleSubmit } = useForm<OverheadDialogConfiguration>({
    defaultValues: {
      speakerPassages,
    },
    mode: "onSubmit",
  });

  const {
    fields: speakerPassageFields,
    insert: insertSpeakerPassage,
    remove: removeSpeakerPassage,
    move: moveSpeakerPassage,
  } = useFieldArray({
    name: "speakerPassages",
    control,
  });

  const handleInsertSpeakerPassage = useCallback(
    (index: number) => {
      insertSpeakerPassage(index, {
        passageId: ulid(),
        passageType: "dialog",
        passage: "",
        passagePrompt: "",
        speakerType: "npc",
        speakerId,
      });
    },
    [insertSpeakerPassage, speakerId]
  );

  const handleRemoveSpeakerPassage = useCallback(
    (index: number) => {
      removeSpeakerPassage(index);
    },
    [removeSpeakerPassage, speakerPassageFields]
  );

  const handleMoveSpeakerPassage = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveSpeakerPassage(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveSpeakerPassage]
  );

  const handleUpdate = useCallback(
    (overheadDialogConfiguration: OverheadDialogConfiguration) => {
      onSubmit(overheadDialogConfiguration);

      onClose();
    },
    [onSubmit, onClose]
  );

  return (
    <>
      <Button color={color} variant={"outline"} borderRadius={0} onClick={onOpen}>
        <Text color={color} casing={"uppercase"}>
          Edit Dialog
        </Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />

        <Form onSubmit={handleSubmit(handleUpdate)}>
          <ModalContent bg={bg}>
            <ModalHeader>
              <Heading>
                <Text color={color} casing={"uppercase"}>
                  Dialog Configuration
                </Text>
              </Heading>
            </ModalHeader>

            <ModalCloseButton color={color} />

            <ModalBody>
              <Stack>
                <Text color={color} casing={"uppercase"}>
                  Speaker Passages
                </Text>

                <Stack>
                  {speakerPassageFields.length === 0 ? (
                    <Center>
                      <Text color={"white"}>No speaker passages (insert new)</Text>
                    </Center>
                  ) : (
                    speakerPassageFields.map(({ passageId }, index) => (
                      <SpeakerPassageComponent
                        key={passageId}
                        index={index}
                        speakerId={speakerId}
                        updateIndex={handleMoveSpeakerPassage}
                        removeIndex={handleRemoveSpeakerPassage}
                        setValue={setValue}
                        watch={watch}
                        color={color}
                      />
                    ))
                  )}
                </Stack>

                <Flex justifyContent={"flex-end"}>
                  <Button onClick={() => handleInsertSpeakerPassage(speakerPassageFields.length)} variant={"outline"}>
                    <Text color={color} textTransform={"uppercase"}>
                      Insert New
                    </Text>
                  </Button>
                </Flex>
              </Stack>
            </ModalBody>

            <ModalFooter gap={2}>
              <Button color={color} variant={"outline"} borderRadius={0} onClick={onClose}>
                <Text color={color} casing={"uppercase"}>
                  Cancel
                </Text>
              </Button>
              <Button color={color} variant={"outline"} borderRadius={0} type={"submit"}>
                <Text color={color} casing={"uppercase"}>
                  Update
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </>
  );
}

export default memo(OverheadDialogConfigurationModal);
