import { memo, useEffect, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType, TargetHandle } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { findLastIndex, findNextIndex } from "../../../utils/handles";
import { Form } from "react-router-dom";

interface GrantRewardNodeData extends NodeData {
  rewardInputCount: number;
}

function GrantRewardNode(props: NodeProps<NodeType<GrantRewardNodeData>>) {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const rewardInputCount = nodeData?.rewardInputCount ?? 0;

  const { handleSubmit, control, watch } = useForm<GrantRewardNodeData>({
    defaultValues: useMemo(
      () => ({
        rewardInputCount,
      }),
      [rewardInputCount]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<GrantRewardNodeData>(nodeId);

  const targetHandleNames = ["in", "reward"];

  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const watchedRewardInputCount = watch("rewardInputCount");

  useEffect(() => {
    const currentCount = targetHandles.filter(({ handleName }) => handleName === "reward").length;

    if (currentCount === watchedRewardInputCount) {
      return;
    }

    const handleName = "reward";
    const headHandles = targetHandles.slice(0, findLastIndex(targetHandles, targetHandleNames, handleName) + 1);
    const tailHandles = targetHandles.slice(findNextIndex(targetHandles, targetHandleNames, handleName));

    const updatedTargetHandles = targetHandles.filter(({ handleName }) => handleName === "reward");

    if (currentCount < watchedRewardInputCount) {
      const count = watchedRewardInputCount - currentCount;

      updatedTargetHandles.push(
        ...[...Array(count)].map(() => {
          const targetHandle: TargetHandle = {
            label: "Reward",
            handleName: "reward",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const count = currentCount - watchedRewardInputCount;

      if (count) {
        updatedTargetHandles.splice(-count);
      }
    }

    updateNodeTargetHandles([...headHandles, ...updatedTargetHandles, ...tailHandles]);
  }, [watchedRewardInputCount, updateNodeTargetHandles]);

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <Form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
          <Stack>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Rewards
                </Text>
              </FormLabel>
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Reward Count
                </Text>
              </FormLabel>
              <Controller
                name={"rewardInputCount"}
                control={control}
                render={({ field: { ref, value, onChange, onBlur, name } }) => (
                  <NumberInput
                    value={value}
                    defaultValue={0}
                    name={name}
                    step={1}
                    min={0}
                    max={16}
                    ref={ref}
                    onChange={(value) => onChange(Number(value))}
                    onBlur={onBlur}
                  >
                    <NumberInputField color={color} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>
          </Stack>
        </Form>
      </FlowNodeWithChildren>
    </>
  );
}

export default memo(GrantRewardNode);
