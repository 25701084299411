import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { HStack, Spinner, Stack, Text } from "@chakra-ui/react";
import ReactJson from "react-json-view";
import SelectUser from "../../base/SelectUser";
import SelectUserByUserId from "../../base/SelectUserByUserId";
import useQuestPointers from "../../../hooks/quests/useQuestPointers";
import { useQuestSearchParamsProvider } from "../../../context/quest/QuestSearchParamsProvider";
import useProfile from "../../../hooks/users/useProfile";
import { useParams } from "react-router";

const NodeDebugger: React.FC = () => {
  const { id: questId } = useParams();
  const { userId, setUserId } = useQuestSearchParamsProvider();
  const { profile } = useProfile(userId);
  const { isLoading, error, questPointers = [] } = useQuestPointers(questId, userId);

  const containerRef = useRef<HTMLDivElement>(null);

  const [scrollableHeight, setScrollableHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const containerRefHeight = containerRef.current?.offsetHeight ?? 0;

    setScrollableHeight(containerRefHeight);

    return () => setScrollableHeight(0);
  }, []);

  if (error) {
    return (
      <HStack p={4}>
        <Text color={"white"} casing={"uppercase"}>
          Error occurred loading quest pointers
        </Text>
      </HStack>
    );
  }

  return (
    <Stack p={2}>
      <SelectUser color={"white"} initialSearchQuery={profile?.displayName ?? ""} value={userId} setValue={setUserId} />

      <SelectUserByUserId color={"color"} value={userId} setValue={setUserId} />

      <Stack ref={containerRef} flexGrow={1} position={"relative"}>
        <HStack p={4} position={"absolute"} top={0} right={0}>
          {isLoading && <Spinner size={"md"} color={"white"} />}
        </HStack>

        <Stack
          flexGrow={1}
          h={scrollableHeight}
          minW={"md"}
          sx={{
            "::-webkit-scrollbar": {
              width: 2,
            },
            "::-webkit-scrollbar-track": {
              bg: "mirage.900",
            },
            "::-webkit-scrollbar-thumb": {
              bg: "indigo.600",
            },
          }}
          overflowY={"auto"}
        >
          <ReactJson style={{ backgroundColor: "transparent" }} src={questPointers} theme={"google"} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(NodeDebugger);
