import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { LiaMousePointerSolid } from "react-icons/lia";
import { Panel } from "reactflow";
import { useQuestSearchParamsProvider } from "../../context/quest/QuestSearchParamsProvider";

function QuestPointerToggleButton() {
  const { showQuestPointers, setShowQuestPointers } = useQuestSearchParamsProvider();

  const handleToggleShowQuestPointers = useCallback(
    () => setShowQuestPointers((showQuestPointers) => !showQuestPointers),
    []
  );

  return (
    <Panel position={"bottom-center"}>
      <Button color={"whiteAlpha.200"} onClick={handleToggleShowQuestPointers}>
        <HStack>
          <Icon color={"white"} as={LiaMousePointerSolid} />

          <Text color={"white"} casing={"uppercase"}>
            {showQuestPointers ? "hide quest pointers" : "show quest pointers"}
          </Text>
        </HStack>
      </Button>
    </Panel>
  );
}

export default memo(QuestPointerToggleButton);
