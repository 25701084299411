import { useQuery } from "@tanstack/react-query";
import { Energy } from "@worldwidewebb/client-quests";
import { getEnergies } from "../../api/energies/energies";

export default function useEnergies() {
  const {
    isLoading,
    isFetching,
    error,
    data: energies,
  } = useQuery<Energy[]>({
    queryKey: ["energy"],
    queryFn: () => getEnergies(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    energies,
  };
}
