import React, { memo, useCallback, useMemo } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react";
import SelectItem from "../../base/SelectItem";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Form } from "react-router-dom";
import SelectSprite from "../../base/SelectSprite";

interface CustomItem extends NodeData {
  customName: string;
  customDescription: string;
  spriteId: string;
  itemName: string;
  quantity: number;
}

function CustomItemNode(props: NodeProps<NodeType<CustomItem>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const customName = nodeData?.customName ?? "";
  const customDescription = nodeData?.customDescription ?? "";
  const spriteId = nodeData?.spriteId ?? "";
  const itemName = nodeData?.itemName ?? "";
  const quantity = nodeData?.quantity ?? 1;

  const { control, register, getValues, setValue, handleSubmit } = useForm<CustomItem>({
    defaultValues: useMemo(
      () => ({
        customName,
        customDescription,
        spriteId,
        itemName,
        quantity,
      }),
      [customName, customDescription, spriteId, itemName, quantity]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<CustomItem>(nodeId);

  return (
    <BaseNodeWithChildren {...props}>
      <Form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
        <Stack p={2}>
          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Custom Name
              </Text>
            </FormLabel>

            <Input color={color} borderColor={color} borderRadius={0} borderWidth={2} {...register("customName")} />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Custom Description
              </Text>
            </FormLabel>

            <Input
              color={color}
              borderColor={color}
              borderRadius={0}
              borderWidth={2}
              {...register("customDescription")}
            />
          </FormControl>

          <SelectSprite color={color} value={getValues("spriteId")} setValue={(value) => setValue("spriteId", value)} />

          <SelectItem color={color} value={getValues("itemName")} setValue={(value) => setValue("itemName", value)} />

          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Quantity
              </Text>
            </FormLabel>
            <Controller
              name={"quantity"}
              control={control}
              render={({ field: { ref, value, onChange, onBlur, name } }) => (
                <NumberInput
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={(value) => onChange(Number(value))}
                >
                  <NumberInputField color={color} borderColor={color} borderRadius={0} borderWidth={2} />
                </NumberInput>
              )}
            />
          </FormControl>
        </Stack>
      </Form>
    </BaseNodeWithChildren>
  );
}

export default memo(CustomItemNode);
