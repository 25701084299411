import { useQuery } from "@tanstack/react-query";
import { ValidationQuest } from "@worldwidewebb/client-quests";
import { getQuestIssues } from "../../api/questIssues/questIssues";

export type QuestIssue = ValidationQuest;

export default function useQuestIssues() {
  const {
    isLoading,
    isFetching,
    error,
    data: questIssues = [],
  } = useQuery<QuestIssue[]>({
    queryKey: ["questIssue"],
    queryFn: () => getQuestIssues(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    questIssues,
  };
}
