import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { memo, MouseEvent, useCallback } from "react";
import { MdRefresh } from "react-icons/md";
import useResetQuests from "../../hooks/quests/useResetQuests";

interface QuestResetButtonProps {
  questIds: string[];
  userId: string;
}

function QuestResetButton({ questIds, userId }: QuestResetButtonProps) {
  const { isResetting, resetQuests } = useResetQuests(questIds, userId);

  const handleResetQuests = useCallback((event: MouseEvent) => {
    event.stopPropagation();

    resetQuests();
  }, [resetQuests]);

  return (
    <Tooltip label={"reset quests"}>
      <IconButton
        aria-label={"reset quests"}
        icon={<Icon as={MdRefresh} position={"absolute"} />}
        color={"green.600"}
        isLoading={isResetting}
        onClick={handleResetQuests}
      />
    </Tooltip>
  );
}

export default memo(QuestResetButton);
