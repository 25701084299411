import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createQuestPointerForUser } from "../../api/quests/questPointers";

interface CreateQuestPointer {
  userId: string;
  questId: string;
  nodeId: string;
}

export default function useCreateQuestPointer() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isCreating, mutate: createQuestPointer } = useMutation({
    mutationFn: ({ userId, questId, nodeId }: CreateQuestPointer) => createQuestPointerForUser(userId, questId, nodeId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questPointer"],
      });

      toast({ title: "Quest pointer create successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest pointer create unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isCreating,
    createQuestPointer,
  };
}
