import { Outlet } from "react-router";
import MultiProvider from "../context/MultiProvider";
import { QuestNodeTypesProvider } from "../context/quest/QuestNodeTypesProvider";
import { QuestsSelectionProvider } from "../context/quests/QuestsSelectionProvider";

export const QuestsLayout = () => (
  <MultiProvider providers={[<QuestNodeTypesProvider />, <QuestsSelectionProvider />]}>
    <Outlet />
  </MultiProvider>
);
