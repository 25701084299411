import { Energy, QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getEnergies(): Promise<Energy[]> {
  try {
    const { data: energies } = await questEditorApi.getEnergies();

    return energies.sort(({ displayName: a }, { displayName: b }) => (a < b ? -1 : a > b ? 1 : 0));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
