import {
  Card,
  CardBody,
  CardHeader,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
} from "@chakra-ui/react";
import { NodeData, NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../../hooks/useUpdateNodeData";

interface FormData extends NodeData {
  displayName: string;
  description: string;
}

function StickyNode(props: NodeProps<NodeType<FormData>>) {
  const {
    id: nodeId,
    data: { color = "black", nodeData },
    selected: isSelected,
  } = props;

  const displayName = nodeData?.displayName;
  const description = nodeData?.description;

  const { handleSubmit, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      displayName,
      description,
    },
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<FormData>(nodeId);

  return (
    <Card bg={"yellow.400"} borderRadius={0} borderColor={"transparent"} minW={"24rem"}>
      <Form onSubmit={handleSubmit(updateNodeData)} onChange={handleSubmit(updateNodeData)}>
        <CardHeader
          bg={"yellow.400"}
          borderBottomWidth={1}
          borderTopWidth={8}
          borderTopColor={isSelected ? "white" : color}
          style={{ filter: isSelected ? "drop-shadow(0px -2px 2px white)" : undefined, position: "relative" }}
        >
          <Editable
            color={color}
            placeholder={"no title"}
            value={getValues("displayName")}
            onChange={(value) => setValue("displayName", value)}
          >
            <EditablePreview fontWeight={500} />
            <EditableInput />
          </Editable>
        </CardHeader>

        <CardBody className={"nodrag"}>
          <Editable
            color={color}
            placeholder={"no content"}
            value={getValues("description")}
            onChange={(value) => setValue("description", value)}
          >
            <EditablePreview whiteSpace={"pre-wrap"} />
            <EditableTextarea />
          </Editable>
        </CardBody>
      </Form>
    </Card>
  );
}

export default memo(StickyNode);
