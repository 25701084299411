import { ExoticComponent } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import BaseNode from "./base/BaseNode";
import StickyNode from "./base/StickyNode";
import ModNode from "./ModNode";
import LocationNode from "./LocationNode";
import ItemNameAndQuantityNode from "./ItemNameAndQuantityNode";
import NpcNode from "./NpcNode";
import DialogNode from "./DialogNode";
import StartByChattingToNpcNode from "./StartByChattingToNpcNode";
import FlowNode from "./FlowNode";
import CreateQuestLogNode from "./CreateQuestLogNode";
import CompleteQuestLogNode from "./CompleteQuestLogNode";
import CreateQuestTaskNode from "./CreateQuestTaskNode";
import CompleteQuestTaskNode from "./CompleteQuestTaskNode";
import WaitForPlayerLocationNode from "./WaitForPlayerLocationNode";
import EndNode from "./EndNode";
import SplitNode from "./SplitNode";
import JoinNode from "./JoinNode";
import StartWithNpcAtLocationNode from "./StartWithNpcAtLocationNode";
import SpawnEntityAtLocationNode from "./SpawnEntityAtLocationNode";
import DynamicItemNameAndQuantityNode from "./DynamicItemNameAndQuantityNode";
import DynamicLocationNode from "./DynamicLocationNode";
import DynamicNpcNode from "./DynamicNpcNode";
import StartNode from "./StartNode";
import SwitchNode from "./SwitchNode";
import BooleanNode from "./BooleanNode";
import NumberNode from "./NumberNode";
import BooleanOperatorNode from "./BooleanOperatorNode";
import RelationalOperatorNode from "./RelationalOperatorNode";
import StartIfPlayerAtLocationNode from "./StartIfPlayerAtLocationNode";
import NpcMoveToPlayerNode from "./NpcMoveToPlayerNode";
import PlayerMoveToNpcNode from "./PlayerMoveToNpcNode";
import NpcMoveByXYNode from "./NpcMoveByXYNode";
import VendorNode from "./VendorNode";
import CraftingStationNode from "./CraftingStationNode";
import SetFlagNode from "./SetFlagNode";
import SetFlagToValueNode from "./SetFlagToValueNode";
import ClearFlagNode from "./ClearFlagNode";
import FlagNode from "./FlagNode";
import AdditiveOperatorNode from "./AdditiveOperatorNode";
import MultiplicativeOperatorNode from "./MultiplicativeOperatorNode";
import PlayerHasStartedQuestNode from "./PlayerHasStartedQuestNode";
import PlayerHasCompletedQuestNode from "./PlayerHasCompletedQuestNode";
import CameraFollowNpcNode from "./CameraFollowNpcNode";
import CameraPanToLocationNode from "./CameraPanToLocationNode";
import AllegianceNode from "./AllegianceNode";
import ModifyPlayerAllegianceNode from "./ModifyPlayerAllegianceNode";
import AiDecisionNode from "./AiDecisionNode";
import PlayerHasEquipmentNode from "./PlayerHasEquipmentNode";
import PlayerHasItemInEquipmentSlotNode from "./PlayerHasItemInEquipmentSlotNode";
import CompareItemsNode from "./CompareItemsNode";
import WaitForNpcInteractionNode from "./WaitForNpcInteractionNode";
import WaitForChatWithNpcNode from "./WaitForChatWithNpcNode";
import RoomNode from "./RoomNode";
import CoordinatesNode from "./CoordinatesNode";
import ShowEmoteOnNpcNode from "./ShowEmoteOnNpcNode";
import StartCinematicNode from "./StartCinematicNode";
import HideEmoteOnNpcNode from "./HideEmoteOnNpcNode";
import SetMusicNode from "./SetMusicNode";
import SetNpcContextNode from "./SetNpcContextNode";
import SubgraphNode from "./base/SubgraphNode";
import SubgraphInputNode from "./base/SubgraphInputNode";
import NpcSetSpriteNode from "./NpcSetSpriteNode";
import PlayerCurrentSkillLevelNode from "./PlayerCurrentSkillLevelNode";
import DoorNode from "./DoorNode";
import WaitForEntityInteractionNode from "./WaitForEntityInteractionNode";
import StartWithSpriteObjectNode from "./StartWithSpriteObjectNode";
import SpawnSpriteEntityNode from "./SpawnSpriteEntityNode";
import OpenUIElementNode from "./OpenUIElementNode";
import CloseUIElementNode from "./CloseUIElementNode";
import HighlightUIElementNode from "./HighlightUIElementNode";
import ResetHighlightUIElementNode from "./ResetHighlightUIElementNode";
import SpawnEnemySequencerNode from "./SpawnEnemySequencerNode";
import WaitForEnemySequencerNode from "./WaitForEnemySequencerNode";
import PlayerGrantSkillExperiencePointsNode from "./PlayerGrantSkillExperiencePointsNode";
import PlayerGrantSkillLevelNode from "./PlayerGrantSkillLevelNode";
import PlayerCurrentSkillExperiencePointsNode from "./PlayerCurrentSkillExperiencePointsNode";
import FeatureFlagNode from "./FeatureFlagNode";
import SetFeatureFlagNode from "./SetFeatureFlagNode";
import ClearFeatureFlagNode from "./ClearFeatureFlagNode";
import CameraSnapToLocationNode from "./CameraSnapToLocationNode";
import NpcSetDirectionNode from "./NpcSetDirectionNode";
import OpenUrlNode from "./OpenUrlNode";
import WatchPlayerActionNode from "./WatchPlayerActionNode";
import PlayerActionCountNode from "./PlayerActionCountNode";
import SpawnEntityNode from "./SpawnEntityNode";
import SpawnObjectNode from "./SpawnObjectNode";
import PlayerUnequipEquipmentNode from "./PlayerUnequipEquipmentNode";
import NpcUnequipEquipmentNode from "./NpcUnequipEquipmentNode";
import PlaySfxNode from "./PlaySfxNode";
import PlaySfxPositionalNode from "./PlaySfxPositionalNode";
import PlayAmbienceNode from "./PlayAmbienceNode";
import PlayAmbiencePositionalNode from "./PlayAmbiencePositionalNode";
import NpcFaceNpcNode from "./NpcFaceNpcNode";
import NpcFacePlayerNode from "./NpcFacePlayerNode";
import AllegianceAndAmountNode from "./AllegianceAndAmountNode";
import SkillAndExperiencePointsNode from "./SkillAndExperiencePointsNode";
import SkillAndLevelsNode from "./SkillAndLevelsNode";
import SetLutNode from "./SetLutNode";
import CompleteQuestNode from "./CompleteQuestNode";
import ShowTutorialNode from "./ShowTutorialNode";
import TakeItemNode from "./TakeItemNode";
import GiveItemNode from "./GiveItemNode";
import PrefetchSfxNode from "./PrefetchSfxNode";
import PrefetchAmbienceNode from "./PrefetchAmbienceNode";
import PrefetchMusicNode from "./PrefetchMusicNode";
import NpcMoveToLocationNode from "./NpcMoveToLocationNode";
import ChangePointerPriorityNode from "./ChangePointerPriorityNode";
import MapEntityNode from "./MapEntityNode";
import CustomItemNode from "./CustomItemNode";
import EnergyRewardNode from "./EnergyRewardNode";
import LootTableRewardNode from "./LootTableRewardNode";
import GrantRewardNode from "./GrantRewardNode";

export const nodeTypes: Record<string, ExoticComponent<NodeProps<NodeType>>> = {
  StickyNode,
  BaseNode,
  SubgraphNode,
  SubgraphInputNode,
  FlowNode,
  LocationNode,
  DynamicItemNameAndQuantityNode,
  DynamicLocationNode,
  DynamicNpcNode,
  ItemNameAndQuantityNode,
  NpcNode,
  DialogNode,
  StartByChattingToNpcNode,
  StartWithNpcAtLocationNode,
  StartIfPlayerAtLocationNode,
  SpawnEntityAtLocationNode,
  CreateQuestLogNode,
  CompleteQuestLogNode,
  CreateQuestTaskNode,
  CompleteQuestTaskNode,
  WaitForPlayerLocationNode,
  EndNode,
  SplitNode,
  JoinNode,
  StartNode,
  SwitchNode,
  BooleanNode,
  NumberNode,
  BooleanOperatorNode,
  RelationalOperatorNode,
  AdditiveOperatorNode,
  MultiplicativeOperatorNode,
  NpcMoveToPlayerNode,
  NpcSetSpriteNode,
  PlayerCurrentSkillLevelNode,
  DoorNode,
  WaitForEntityInteractionNode,
  StartWithSpriteObjectNode,
  SpawnSpriteEntityNode,
  OpenUIElementNode,
  CloseUIElementNode,
  HighlightUIElementNode,
  ResetHighlightUIElementNode,
  NpcMoveByXYNode,
  PlayerMoveToNpcNode,
  VendorNode,
  CraftingStationNode,
  SetFlagNode,
  SetFlagToValueNode,
  ClearFlagNode,
  FlagNode,
  PlayerHasStartedQuestNode,
  PlayerHasCompletedQuestNode,
  CameraFollowNpcNode,
  CameraPanToLocationNode,
  AllegianceNode,
  ModifyPlayerAllegianceNode,
  AiDecisionNode,
  PlayerHasEquipmentNode,
  PlayerHasItemInEquipmentSlotNode,
  CompareItemsNode,
  WaitForNpcInteractionNode,
  WaitForChatWithNpcNode,
  CoordinatesNode,
  RoomNode,
  StartCinematicNode,
  ShowEmoteOnNpcNode,
  HideEmoteOnNpcNode,
  PrefetchMusicNode,
  SetMusicNode,
  SetNpcContextNode,
  SpawnEnemySequencerNode,
  WaitForEnemySequencerNode,
  PlayerGrantSkillExperiencePointsNode,
  PlayerGrantSkillLevelNode,
  PlayerCurrentSkillExperiencePointsNode,
  FeatureFlagNode,
  SetFeatureFlagNode,
  ClearFeatureFlagNode,
  CameraSnapToLocationNode,
  NpcSetDirectionNode,
  OpenUrlNode,
  WatchPlayerActionNode,
  PlayerActionCountNode,
  SpawnEntityNode,
  SpawnObjectNode,
  PlayerUnequipEquipmentNode,
  NpcUnequipEquipmentNode,
  PrefetchSfxNode,
  PlaySfxNode,
  PlaySfxPositionalNode,
  PrefetchAmbienceNode,
  PlayAmbienceNode,
  PlayAmbiencePositionalNode,
  NpcFaceNpcNode,
  NpcFacePlayerNode,
  AllegianceAndAmountNode,
  SkillAndExperiencePointsNode,
  SkillAndLevelsNode,
  ModNode,
  SetLutNode,
  CompleteQuestNode,
  ShowTutorialNode,
  TakeItemNode,
  GiveItemNode,
  NpcMoveToLocationNode,
  ChangePointerPriorityNode,
  MapEntityNode,
  CustomItemNode,
  EnergyRewardNode,
  LootTableRewardNode,
  GrantRewardNode,
};

export const nodeTypeDictionary: Record<string, ExoticComponent<NodeProps<NodeType>>> = Object.fromEntries(
  Object.entries(nodeTypes).map(([nodeTypeName, nodeType]) => [nodeTypeName, nodeType])
);
