import { ChakraProps, Icon, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { memo, MouseEvent, useCallback } from "react";
import { LiaCopySolid } from "react-icons/lia";
import { QuestPointerStatus } from "../../models/questPointerStatus";
import getQuestPointerStatusColor from "../../utils/getQuestPointerStatusColor";

interface QuestPointerCopyButtonProps extends ChakraProps {
  isSelected: boolean;
  questPointerStatus: QuestPointerStatus;
}

function QuestPointerCopyButton({ color, isSelected, questPointerStatus }: QuestPointerCopyButtonProps) {
  const { questPointerId } = questPointerStatus;

  const toast = useToast();

  const handleCopyQuestPointerIdToClipboard = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (questPointerId == null) {
        return;
      }

      navigator.clipboard.writeText(questPointerId).then(() =>
        toast({
          title: `Copied ${questPointerId} to clipboard`,
          status: "info",
        })
      );
    },
    [toast, questPointerId]
  );

  const questPointerStatusColor = getQuestPointerStatusColor(questPointerStatus) ?? color;

  return (
    <Tooltip
      label={"copy quest pointer id"}
      bg={"theme.dark.background"}
      borderColor={questPointerStatusColor}
      borderWidth={2}
      color={questPointerStatusColor}
    >
      <IconButton
        aria-label={"copy quest pointer id"}
        icon={<Icon as={LiaCopySolid} position={"absolute"} />}
        variant={"ghost"}
        isRound={true}
        onClick={handleCopyQuestPointerIdToClipboard}
        color={isSelected ? "theme.dark.background" : questPointerStatusColor}
      />
    </Tooltip>
  );
}

export default memo(QuestPointerCopyButton);
