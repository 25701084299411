import { JSONSchema7Definition } from "json-schema";

export const JSONSchemaStartConditions: JSONSchema7Definition = {
  type: "array",
  items: {
    type: "object",
    properties: {
      id: {
        type: "string",
      },
      type: {
        type: "string",
        enum: [
          "quest_completed",
          "quest_started",
          "player_has_item",
          "player_id_is",
          "quest_flag_is_set",
          "quest_flag_is_not_set",
          "user_has_feature_flag",
          "user_does_not_have_feature_flag",
          "allegiance_is_above",
          "allegiance_is_below",
          "skill_level_is_above",
          "skill_level_is_below",
        ],
      },
      value: {},
    },
    dependencies: {
      type: {
        oneOf: [
          {
            properties: {
              type: {
                enum: ["quest_completed"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["quest_started"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["player_has_item"],
              },
              value: {
                type: "object",
                properties: {
                  itemName: {
                    type: "string",
                  },
                  quantity: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["player_id_is"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["quest_flag_is_set"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["quest_flag_is_not_set"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["user_has_feature_flag"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["user_does_not_have_feature_flag"],
              },
              value: {
                type: "string",
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["allegiance_is_above"],
              },
              value: {
                type: "object",
                properties: {
                  allegianceId: {
                    type: "string",
                  },
                  allegianceAmount: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["allegiance_is_below"],
              },
              value: {
                type: "object",
                properties: {
                  allegianceId: {
                    type: "string",
                  },
                  allegianceAmount: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["skill_level_is_above"],
              },
              value: {
                type: "object",
                properties: {
                  skillId: {
                    type: "string",
                  },
                  level: {
                    type: "number",
                  },
                },
              },
            },
          },
          {
            properties: {
              type: {
                enum: ["skill_level_is_below"],
              },
              value: {
                type: "object",
                properties: {
                  skillId: {
                    type: "string",
                  },
                  level: {
                    type: "number",
                  },
                },
              },
            },
          },
        ],
      },
    },
  },
};
