import { DragEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  ControlButton,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  ReactFlowInstance,
  ReactFlowProps,
  SelectionMode,
  updateEdge,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { edgeTypeDictionary } from "../components/reactFlow/edgeTypes";
import { nodeTypeDictionary } from "../components/reactFlow/nodeTypes";
import { Center, Flex, Icon, Spinner, useDisclosure, useToast } from "@chakra-ui/react";
import { ulid } from "ulid";
import StyledBackground from "../components/reactFlow/StyledBackground";
import StyledControls from "../components/reactFlow/StyledControls";
import StyledMiniMap from "../components/reactFlow/StyledMiniMap";
import { ControlToolbar } from "../components/reactFlow/ControlToolbar";
import { useParams } from "react-router-dom";
import { ConnectionLine } from "../components/reactFlow/ConnectionLine";
import { createHandleId } from "../utils/handleId";
import { NodeData, NodeType } from "../models/nodeType";
import { QuestWithId } from "../models/api/quest";
import useNodeTypesDiff, { NodeDiff } from "../hooks/useNodeTypesDiff";
import useNodeHandlesValidation from "../hooks/useNodeHandlesValidation";
import { useCopyAndPasteShortcuts } from "../hooks/useCopyAndPasteShortcuts";
import { UserList } from "../components/UserList";
import useNodeTypeNameLookup from "../hooks/useNodeTypeNameLookup";
import { useConnectionProvider } from "../context/ConnectionContext";
import { useToken } from "@chakra-ui/system";
import { BiSolidInfoCircle } from "react-icons/bi";
import { LiaHandPaperSolid, LiaHandPointer } from "react-icons/lia";
import { useUserSettingsProvider } from "../context/UserSettingsContext";
import ControlSchemeInfo from "../components/reactFlow/ControlSchemeInfo";
import { useActiveUsersProvider } from "../context/ActiveUsersContext";
import { useCopyAndPaste } from "../hooks/useCopyAndPaste";
import { useQuestProvider } from "../context/QuestContext";
import { EdgeType } from "../models/edgeType";
import ContextMenu from "../components/base/ContextMenu";
import QuestNodeSelector from "../components/navigation/elements/QuestNodeSelector";
import useExportRuntimeData from "../hooks/useExportRuntimeData";
import NodeSearchPanel from "../features/reactflow/nodes/NodeSearchPanel";
import NodeDeployPanel from "../features/reactflow/nodes/NodeDeployPanel";
import { getQuest } from "../api/quests/quests";
import QuestPointerToggleButton from "../components/quests/QuestPointerToggleButton";
import { useNodeTypesProvider } from "../context/reactflow/NodeTypesProvider";

// TODO: REFACTOR IN PROGRESS

const QuestEditor = () => {
  const { quest, saveQuest } = useQuestProvider();
  const { nodeTypes } = useNodeTypesProvider();
  const [nodes, setNodes] = useState<Node<NodeType>[]>(quest?.data?.nodes ?? []);
  const [edges, setEdges] = useState<Edge<EdgeType>[]>(quest?.data?.edges ?? []);
  const { updatedEdges, updatedNodes, updatedNodeDiffs } = useNodeTypesDiff(edges, nodes, nodeTypes);
  const hasDefinitionsUpgrade = updatedNodeDiffs.length !== 0;
  const [updatedNodeDiffsLog, setUpdatedNodeDiffsLog] = useState<NodeDiff[]>([]);
  const { id } = useParams();
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onInit = useCallback((reactFlowInstance: ReactFlowInstance) => setReactFlowInstance(reactFlowInstance), []);

  const { activeUsers } = useActiveUsersProvider();

  const { onEdgeUpdateStart, onEdgeUpdateEnd } = useConnectionProvider();

  const { isValidConnection } = useNodeHandlesValidation();

  const handleDefinitionsUpgrade = useCallback(() => {
    setUpdatedNodeDiffsLog(updatedNodeDiffs);

    // https://github.com/wbkd/react-flow/issues/3198
    setEdges([]);
    setNodes([]);
    setTimeout(() => {
      setNodes(updatedNodes);
      setEdges(updatedEdges);
    }, 0);
  }, [updatedEdges, updatedNodes, updatedNodeDiffs, nodeTypes]);

  const handleLoadAuto = useCallback(() => {
    if (id == null) {
      return;
    }

    const quests = localStorage.getItem("quests-autosave");

    if (quests == null) {
      return;
    }

    const quest = JSON.parse(quests)[id];

    if (quest == null) {
      return;
    }

    // https://github.com/wbkd/react-flow/issues/3198
    setEdges([]);
    setNodes([]);
    setTimeout(() => {
      setNodes(quest.data.nodes);
      setEdges(quest.data.edges);
    }, 0);
  }, [id]);

  const handleLoad = useCallback(async () => {
    if (id == null) {
      return;
    }

    const quest = await getQuest(id);

    // https://github.com/wbkd/react-flow/issues/3198
    setEdges([]);
    setNodes([]);
    setTimeout(() => {
      setNodes(quest.data?.nodes ?? []);
      setEdges(quest.data?.edges ?? []);
    }, 100);
  }, [getQuest, id]);

  const toast = useToast();

  const { exportQuestNodes } = useExportRuntimeData();

  const handleSave = useCallback(
    async (questPartial?: Partial<Omit<QuestWithId, "data">>) => {
      if (quest == null) {
        return;
      }

      const updatedNodes = nodes.map((node) => {
        const initialNodeType = nodeTypes.find(({ nodeName }) => nodeName === node.data.nodeName);
        const jsonSchema = initialNodeType?.nodeData?.templateData?.jsonSchema;

        const {
          data: { nodeData = {} },
        } = node;

        const updatedNodeData: NodeData = {
          ...nodeData,
          templateData: {
            ...nodeData.templateData,
            jsonSchema,
          },
        };

        return {
          ...node,
          data: {
            ...node.data,
            nodeData: updatedNodeData,
          },
        };
      });

      const updatedEdges = edges.slice();

      saveQuest(
        quest.questId,
        { nodes: updatedNodes, edges: updatedEdges },
        {
          ...questPartial,
        }
      );
    },
    [quest, nodes, edges, nodeTypes, saveQuest]
  );

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((changedNodes) => applyNodeChanges(changes, changedNodes));
  }, []);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((changedEdges) => applyEdgeChanges(changes, changedEdges));
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    setEdges((edges) => updateEdge(oldEdge, newConnection, edges));
  }, []);

  const { onConnect } = useConnectionProvider();

  const { project } = useReactFlow();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useCopyAndPasteShortcuts(wrapperRef);
  const { screenToFlowPosition } = useReactFlow();

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const { getLatestType } = useNodeTypeNameLookup();
  const { paste } = useCopyAndPaste();

  const onDrop = useCallback(
    async (event: DragEvent) => {
      event.stopPropagation();

      const nodeName = event.dataTransfer.getData("application/reactflow");
      const nodePosition = screenToFlowPosition({ x: event.clientX, y: event.clientY });

      const nodeType = nodeTypes.find((nodeType) => nodeType.nodeName === nodeName);

      if (nodeType == null) {
        return;
      }

      nodeType.targetHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId ??= createHandleId(nodeHandle);
      });

      nodeType.sourceHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId ??= createHandleId(nodeHandle);
      });

      const type = getLatestType(nodeType, nodeName);

      const { nodes } = await paste(
        [
          {
            id: ulid(),
            type,
            position: nodePosition,
            data: structuredClone(nodeType),
          },
        ],
        [],
        true
      );

      const [node] = nodes;
      node.position = nodePosition;

      setNodes((nodes) => nodes.concat(node));
    },
    [screenToFlowPosition, nodeTypes, getLatestType, paste]
  );

  const handleUpdateQuestName = useCallback(
    async (questName: string) => {
      if (quest == null) {
        return;
      }

      await handleSave({ name: questName });
    },
    [quest, handleSave]
  );

  const handleExportToFile = useCallback(() => {
    if (!id) {
      return;
    }

    const exportJSON = JSON.stringify(exportQuestNodes(nodes, edges, nodeTypes), null, 2);

    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(exportJSON)}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${quest?.name}.json`;

    link.click();
    link.remove();
  }, [nodes, edges, id, nodeTypes, exportQuestNodes]);

  const handleExportToClipboard = useCallback(() => {
    if (!id) {
      return;
    }

    const exportJSON = JSON.stringify(exportQuestNodes(nodes, edges, nodeTypes), null, 2);

    navigator.clipboard.writeText(exportJSON).catch((error) => console.error(error));
  }, [nodes, edges, id, nodeTypes, exportQuestNodes]);

  const handleClickNodeInSelectionRectangle = useCallback(
    (event: Event) => {
      const { target, clientX, clientY } = event as unknown as MouseEvent;

      if (!(target instanceof HTMLElement)) {
        return;
      }

      // noinspection SpellCheckingInspection
      if (!target.closest(".react-flow__nodesselection")) {
        return;
      }

      const { x: clickX, y: clickY } = screenToFlowPosition({ x: clientX, y: clientY });

      const selectedNodes = reactFlowInstance?.getNodes().filter(({ selected }) => selected) ?? [];
      const [clickedNode] = selectedNodes.filter(
        ({ position: { x, y }, width, height }) =>
          x <= clickX && clickX < x + (width || 0) && y <= clickY && clickY < y + (height || 0)
      );

      if (clickedNode == null) {
        return;
      }

      reactFlowInstance?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: clickedNode.id === node.id,
        }))
      );

      reactFlowInstance?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );

      target.remove();
    },
    [reactFlowInstance, screenToFlowPosition]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickNodeInSelectionRectangle);

    return () => {
      window.removeEventListener("click", handleClickNodeInSelectionRectangle);
    };
  }, [handleClickNodeInSelectionRectangle]);

  const handleClickNodeInSelection = useCallback(
    ({ ctrlKey, metaKey }: MouseEvent, { id: nodeId }: Node) => {
      // corresponds to multiSelectionKeyCode
      if (ctrlKey || metaKey) {
        return;
      }

      const selectedNodes = reactFlowInstance?.getNodes().filter(({ selected }) => selected) ?? [];

      if (selectedNodes.length <= 1) {
        return;
      }

      reactFlowInstance?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        }))
      );

      reactFlowInstance?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );
    },
    [reactFlowInstance]
  );

  useEffect(() => {
    const currentUserId = activeUsers.find(({ isCurrentUser }) => isCurrentUser)?.userId;

    if (!currentUserId) {
      return;
    }

    if (nodes.length !== 0) {
      return;
    }

    const initialStartNodeType = nodeTypes.find((nodeType) => nodeType.nodeName === "start");

    initialStartNodeType?.targetHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    initialStartNodeType?.sourceHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    const initialStartNode = {
      id: ulid(),
      type: "StartNode",
      position: { x: 0, y: 0 },
      data: structuredClone({
        ...initialStartNodeType,
        nodeData: {
          allStartConditions: [
            {
              id: ulid(),
              type: "player_id_is",
              value: currentUserId,
            },
          ],
          anyStartConditions: [],
        },
      }),
    };

    const initialEndNodeType = nodeTypes.find((nodeType) => nodeType.nodeName === "end");

    initialEndNodeType?.targetHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    initialEndNodeType?.sourceHandles?.forEach((nodeHandle) => {
      nodeHandle.handleId = createHandleId(nodeHandle);
    });

    const initialEndNode = {
      id: ulid(),
      type: "EndNode",
      position: { x: 1000, y: 0 },
      data: structuredClone(initialEndNodeType),
    };

    setNodes([initialStartNode, initialEndNode]);
  }, [activeUsers, nodeTypes, quest]);

  useEffect(() => {
    if (!reactFlowInstance) {
      return;
    }

    setTimeout(() => {
      reactFlowInstance.fitView();

      setIsLoading(false);
    }, 100);
  }, [reactFlowInstance]);

  useEffect(() => {
    if (!quest?.name) {
      return;
    }

    document.title = `Quest | ${quest.name}`;

    return () => {
      document.title = "Quest Editor";
    };
  }, [quest]);

  const nodeColors = useToken(
    "colors",
    nodeTypes.map(({ color }) => color ?? "white")
  );

  const nodeColorDictionary = useMemo(
    () => Object.fromEntries(nodeTypes.map(({ color }, index) => [color ?? "white", nodeColors[index]])),
    [nodeTypes, nodeColors]
  );

  const getNodeColor = useCallback(
    (colorToken?: string) => {
      if (colorToken == null) {
        return "#FFF";
      }

      return nodeColorDictionary[colorToken];
    },
    [nodeColorDictionary]
  );

  const { controlScheme, toggleControlScheme } = useUserSettingsProvider();

  const navigationConfig: ReactFlowProps = useMemo(
    () =>
      controlScheme === "primary"
        ? {
            multiSelectionKeyCode: ["Meta", "Control"],
            panActivationKeyCode: "Shift",
            panOnDrag: [1],
            selectionKeyCode: null,
            selectionOnDrag: true,
          }
        : {
            panOnScroll: true,
            selectionKeyCode: ["Meta", "Control"],
          },
    [controlScheme]
  );

  const { onClose, onOpen, isOpen } = useDisclosure();

  useEffect(() => {
    setNodes(quest?.data?.nodes ?? []);
    setEdges(quest?.data?.edges ?? []);
  }, [quest]);

  return (
    <ContextMenu
      renderMenu={(x, y) => <QuestNodeSelector questNodeTypes={nodeTypes} x={x} y={y} maxH={"40vh"} />}
    >
      {(ref) => (
        <Flex display={"grid"} ref={ref}>
          <Flex ref={wrapperRef} tabIndex={0}>
            {isLoading && (
              <Center bg={"mirage.900"} position={"fixed"} top={0} bottom={0} left={0} right={0} zIndex={1000}>
                <Spinner size={"xl"} color={"white"} />
              </Center>
            )}

            <ReactFlow
              onInit={onInit}
              nodeTypes={nodeTypeDictionary}
              edgeTypes={edgeTypeDictionary}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onEdgeUpdate={onEdgeUpdate}
              connectionLineComponent={ConnectionLine}
              onConnect={onConnect}
              onEdgeUpdateStart={(_, edge) => onEdgeUpdateStart(edge)}
              onEdgeUpdateEnd={onEdgeUpdateEnd}
              isValidConnection={isValidConnection}
              proOptions={{ hideAttribution: true }}
              deleteKeyCode={["Backspace", "Delete"]}
              selectionMode={SelectionMode.Partial}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onNodeClick={handleClickNodeInSelection}
              minZoom={0.125}
              maxZoom={1}
              {...navigationConfig}
            >
              <StyledBackground />
              <StyledControls position="top-right" showInteractive={false}>
                <ControlButton onClick={onOpen}>
                  <Icon as={BiSolidInfoCircle} />
                </ControlButton>
                <ControlButton onClick={toggleControlScheme}>
                  <Icon as={controlScheme === "primary" ? LiaHandPointer : LiaHandPaperSolid} />
                </ControlButton>
              </StyledControls>
              <StyledMiniMap
                position="bottom-right"
                pannable={true}
                zoomable={true}
                nodeColor={(node: Node<NodeType>) => getNodeColor(node.data.color)}
              />

              <QuestPointerToggleButton />
            </ReactFlow>

            <ControlToolbar
              title={quest?.name ?? ""}
              onUpdateTitle={handleUpdateQuestName}
              onLoadAuto={handleLoadAuto}
              onLoad={handleLoad}
              onExportToFile={handleExportToFile}
              onExportToClipboard={handleExportToClipboard}
              hasDefinitionsUpgrade={hasDefinitionsUpgrade}
              onDefinitionsUpgrade={handleDefinitionsUpgrade}
              nodeDiffs={updatedNodeDiffsLog}
              isReady={quest?.isReady ?? false}
            />

            <ControlSchemeInfo isOpen={isOpen} onClose={onClose} />

            <NodeDeployPanel quest={quest} />
            <NodeSearchPanel color={"white"} />

            <UserList users={activeUsers} />
          </Flex>
        </Flex>
      )}
    </ContextMenu>
  );
};

export default QuestEditor;
