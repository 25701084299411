import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { resetQuests as resetQuestsAsync } from "../../api/quests/quests";

export default function useResetQuests(questIds: string[], userId: string) {
  const toast = useToast();

  const { isPending: isResetting, mutate: resetQuests } = useMutation({
    mutationFn: () => resetQuestsAsync(questIds, userId),
    onSuccess: async () => {
      toast({ title: "Quests reset successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quests reset unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isResetting,
    resetQuests,
  };
}
