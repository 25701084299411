import { useCallback } from "react";
import { json } from "react-router";
import { AxiosError } from "axios";
import { getSessionProfile } from "../../api/users/users";

const useQuestsOverviewLoader = () => {
  const loader = useCallback(async () => {
    const sessionProfile = await getSessionProfile();

    try {
      return {
        sessionProfile,
      };
    } catch (error) {
      console.error(error);

      if (error instanceof AxiosError) {
        throw json(
          {
            message: error.response?.data.message,
            details: error.response?.data.details,
          },
          {
            status: error.response?.data.message === "Unauthorized" ? 401 : error.status,
          }
        );
      }

      throw json(
        {
          message: "Error occurred fetching quests...",
        },
        {
          status: 500,
        }
      );
    }
  }, []);

  return { loader };
};

export default useQuestsOverviewLoader;
