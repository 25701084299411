import { QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getQuestIssues() {
  try {
    const { data: questIssues } = await questEditorApi.generateValidationReport();

    return questIssues;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
