import { useMemo } from "react";
import { useParams } from "react-router";
import { useQuestSearchParamsProvider } from "../../context/quest/QuestSearchParamsProvider";
import { mapQuestPointerToQuestPointerStatus, QuestPointerStatus } from "../../models/questPointerStatus";
import useQuestPointers from "./useQuestPointers";

export default function useQuestPointerStatuses() {
  const { id: questId } = useParams();
  const { userId } = useQuestSearchParamsProvider();

  const { isLoading, error, questPointers } = useQuestPointers(questId, userId);

  const questPointerStatuses: QuestPointerStatus[] | undefined = useMemo(
    () => questPointers?.map(mapQuestPointerToQuestPointerStatus),
    [questPointers]
  );

  return {
    isLoading,
    error,
    questPointerStatuses,
  };
}
